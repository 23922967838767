<template>
  <div class="lease-tenant-detail">
    <div class="top">
      <el-button type="primary" v-if="isShowEditBtn"
        @click="addRoommateBtn">添加同住人
      </el-button>
      <el-button type="primary" v-if="isShowEditBtn && !isPMS"
        @click="platoonRoomBtn">修改排房信息
      </el-button>
    </div>
    <div class="tenant-cont">
      <div class="cont-box" v-if='signatoryData.stateStr'>
        <div class="cont-left">
          <img :src="signatoryData.headImg" class="img" />
          <p class="txt">主入住人</p>
        </div>
        <div class="cont-right">
          <div style="padding-bottom:12px">
            <span class="name">{{signatoryData.realName}}</span>
            <span class="gender">{{signatoryData.genderStr}}</span>
            <span class="phone">{{signatoryData.phoneNumStr}}</span>
          </div>
          <p class="card" v-if="apartmentType=='7'">学号/职工号：{{signatoryData.studentCode?signatoryData.studentCode:'--'}}</p>
          <p class="card">签约证件类型：{{signatoryData.idType?certTypeArr[signatoryData.idType]:'--'}}</p>
          <p class="card">签约证件号码：{{signatoryData.idCardNoStr?signatoryData.idCardNoStr:'--'}}</p>
          <div class="state">{{signatoryData.stateStr}}</div>
          <div class="delete" v-if="showDeleteTenantBtn"
            @click="deleteTenant(signatoryData)">删除</div>
        </div>
      </div>
      <div class="cont-box" v-for="(item,index) in roommateDataList" :key="index+1">
        <div class="cont-left">
          <img :src="item.headImg" class="img" />
          <p class="txt">同住人</p>
        </div>
        <div class="cont-right">
          <div style="padding-bottom:12px">
            <span class="name">{{item.realName}}</span>
            <span class="gender">{{item.genderStr}}</span>
            <span class="phone">{{item.phoneNumStr}}</span>
          </div>
          <p class="card" v-if="apartmentType=='7'">学号/职工号：{{item.studentCode?item.studentCode:'--'}}</p>
          <p class="card">签约证件类型：{{item.idType?certTypeArr[item.idType]:'--'}}</p>
          <p class="card">签约证件号码：{{item.idCardNoStr?item.idCardNoStr:'--'}}</p>
          <div class="state">{{item.stateStr}}</div>
          <template v-if="isShowCohabitantDelBtn">
            <div class="delete" v-if="item.state=='1' && (queryData.leaseFrom=='2' || queryData.leaseFrom=='3')"
            @click="deleteBtn(item)">删除</div>
          </template>
          
        </div>
      </div>
      <!-- flex div不够加空白div -->
      <div class="cont-box-empty" v-for="(item,index) in (3-(roommateDataList.length+1)%3)" :key="'k'+index+1">
      </div>
    </div>
    <p class="empty-text" v-if="roommateDataList.length==0 && !signatoryData.stateStr">当前租约暂无房客信息</p>
    <el-dialog title="添加同住人" :visible.sync="addRoommateVisible" class="platoon-room-dialog"
      :close-on-click-modal="false">
      <el-form :model="addRoommateForm" :rules="addRoommateRules" ref="addRoommateForm" label-width="120px">
        <el-form-item label="搜索同住人" prop="searchTenant" style="width:100%" key="addRoommateK1">
          <el-select v-model="addRoommateForm.searchTenant" filterable remote clearable reserve-keyword
            placeholder="手机号" :remote-method="remoteMethod" @change="selectTrigger(addRoommateForm.searchTenant)"
            :loading="loading">
            <el-option v-for="item in searchTenantOptions" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="姓名" prop="realName" key="addRoommateK2">
          <el-input :disabled="addRoommateForm.searchCertFlage|| addRoommateForm.searchPhoneFlage" type="text"
            v-model="addRoommateForm.realName" clearable></el-input>
        </el-form-item>
        <el-form-item label="性别" prop="gender" key="addRoommateK10">
          <el-select v-model="addRoommateForm.gender" :disabled="addRoommateForm.searchCertFlage|| addRoommateForm.searchPhoneFlage">
            <el-option v-for="item in genderList" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="学号/职工号" prop="studentCode" key="addRoommateK11"  v-if="apartmentType=='7' && addRoommateForm.idType!=='7'">
          <el-input type="text" 
            v-model="addRoommateForm.studentCode" clearable></el-input>
        </el-form-item>
        <el-form-item label="是否有证件" prop="haveCert" key="addRoommateK3">
          <el-select v-model="addRoommateForm.haveCert"
            :disabled="addRoommateForm.searchCertFlage|| addRoommateForm.searchPhoneFlage|| addRoommateForm.searchCertChangePhoneFlag || isKey=='1' || isPMS"
            @change="addRoommateHCertChange">
            <el-option v-for="item in haveCertList" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="证件类型" prop="idType" v-if="addRoommateForm.haveCert=='1'" key="addRoommateK4">
          <el-select v-model="addRoommateForm.idType" placeholder="请选择" clearable
            :disabled="addRoommateForm.searchPhoneFlage|| addRoommateForm.searchCertFlage || isKey=='1'"
            @change="idTypeChange(addRoommateForm.idType)">
            <el-option v-for="item in certTypeOptions" :key="item.value" :label="item.label" :value="item.value"
              v-show="item.value!='7' || (apartmentCode && apartmentType=='7' && item.value=='7')">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item :label="addRoommateForm.idType=='7'?'学号/职工号':'证件号码'" prop="idCardNo"
          v-if="addRoommateForm.haveCert=='1'" key="addRoommateK5">
          <el-input type="text" :disabled="addRoommateForm.searchPhoneFlage" v-model="addRoommateForm.idCardNo"
            :placeholder="addRoommateForm.idType=='7'?'学号/职工号':'证件号码'" clearable
            @input="searchIdCardNo(addRoommateForm,'1')">
          </el-input>
        </el-form-item>
        <el-form-item label="上传证件照片" prop="idCardPic1" v-if="isUploadPic=='1'" key="addRoommateK6">
          <!-- <el-form-item label="上传证件照片" prop="idCardPic1"> -->
          <div class="picCont">
            <div class="pic-lt">
              <el-upload class="avatar-uploader" accept="image/*" list-type="picture-card"
                :action="UPLOAD_URL + '/user-service/weChat/uploadPic'" :file-list="addRoommateForm.idCardPic1"
                :on-preview="onPreviewByUploadImg" :before-upload="onBeforeUpload"
                :before-remove="onBeforeRemoveByUploadImg" :on-success="onSuccessByUploadImgOnly('idCardPic1','1')"
                :on-remove="onHandleRemove('idCardPic1','1')" :on-exceed="onExceed"
                :class="{'one-upload-disabled': (addRoommateForm.idCardPic1 && addRoommateForm.idCardPic1.length>0)}">
                <i v-if="addRoommateForm.idCardPic1 && addRoommateForm.idCardPic1.length==0" class="el-icon-plus"></i>
              </el-upload>
            </div>
          </div>
        </el-form-item>
        <el-form-item label="是否有手机号码" prop="havePhone" v-if="addRoommateForm.haveCert=='1'" key="addRoommateK7">
          <el-select v-model="addRoommateForm.havePhone" placeholder="请选择"
            :disabled="addRoommateForm.searchCertFlage||addRoommateForm.searchPhoneFlage || addRoommateForm.searchCertChangePhoneFlag || isPMS"
            @change="addRoommateHavePhoneChange">
            <el-option v-for="item in havePhoneList" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="登录密码" v-if="addRoommateForm.havePhone=='0' && addRoommateForm.haveCert=='1'"
          prop="passWord" key="addRoommateK8">
          <div class="ipnut-box">
            <el-input :type="addRoommateForm.passWordType" clearable @focus="passWordFocus()"
              v-model="addRoommateForm.passWord" style="width:87%!important;"></el-input>
            <p class="tip-right">房客使用证件号码（账号）和登录密码登录房客端，默认密码可修改，密码为6~12位含大小写字母和数字组成</p>
          </div>
        </el-form-item>
        <el-form-item v-if="addRoommateForm.havePhone=='1' || addRoommateForm.haveCert=='0'" label="房客电话"
          prop="phoneNum" key="addRoommateK9">
          <el-input v-model.trim="addRoommateForm.phoneNum" placeholder="房客电话" clearable
            :disabled="addRoommateForm.searchCertFlage" @input="searchRealphone(addRoommateForm.phoneNum)"
            maxlength="11"></el-input>
        </el-form-item>
        <div style="text-align: center;">
          <!-- <el-button @click="onRester()">取消</el-button> -->
          <el-button type="primary" @click="addRoommateSubmit()">确定</el-button>
        </div>
      </el-form>
    </el-dialog>
    <el-dialog title="修改排房信息" class="platoon-room-dialog" :visible.sync="platoonRoomVisible"
      :close-on-click-modal="false">
      <div class="dialog-box">
        <p class="tip-top">备注：已实名的用户不支持修改实名身份信息</p>
        <el-form :model="rowRoomForm" :rules="rules" ref="ruleForm" label-width="120px">
          <el-form-item label="房客信息" class="label-title">
          </el-form-item>
          <el-form-item label="姓名" prop="realName" key="rowRoomK1">
            <el-input type="text" v-model="rowRoomForm.realName" clearable :disabled="rowRoomForm.certifiedFlage">
            </el-input>
          </el-form-item>
           <el-form-item label="性别" key="rowRoomK9">
            <el-select v-model="rowRoomForm.gender" :disabled="rowRoomForm.certifiedFlage">
              <el-option v-for="item in genderList" :key="item.value" :label="item.label" :value="item.value">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="学号/职工号" prop="studentCode" key="rowRoomK10" v-if="apartmentType=='7' && rowRoomForm.idType!=='7'">
            <el-input type="text" v-model="rowRoomForm.studentCode" clearable>
            </el-input>
          </el-form-item>
          <el-form-item label="是否有证件" key="rowRoomK2">
            <el-select v-model="rowRoomForm.haveCert" disabled>
              <el-option v-for="item in haveCertList" :key="item.value" :label="item.label" :value="item.value">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="证件类型" prop="idType" v-if="rowRoomForm.haveCert=='1'" key="rowRoomK3">
            <el-select v-model="rowRoomForm.idType" placeholder="请选择" clearable :disabled="rowRoomForm.certifiedFlage"
              @change="idTypeChange(rowRoomForm.idType)">
              <el-option v-for="item in certTypeOptions" :key="item.value" :label="item.label" :value="item.value"
                v-show="item.value!='7' || (apartmentCode && apartmentType=='7' && item.value=='7')">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item :label="rowRoomForm.idType=='7'?'学号/职工号':'证件号码'" prop="idCardNo" v-if="rowRoomForm.haveCert=='1'"
            key="rowRoomK4">
            <el-input type="text" v-model="rowRoomForm.idCardNo" clearable :disabled="rowRoomForm.certifiedFlage">
            </el-input>
          </el-form-item>
          <el-form-item label="上传证件照片" prop="idCardPic1" v-if="isUploadPic=='1'" key="rowRoomK5">
            <!-- <el-form-item label="上传证件照片" prop="idCardPic1"> -->
            <div class="picCont">
              <div class="pic-lt">
                <el-upload class="avatar-uploader" accept="image/*" list-type="picture-card"
                  :action="UPLOAD_URL + '/user-service/weChat/uploadPic'" :file-list="rowRoomForm.idCardPic1"
                  :on-preview="onPreviewByUploadImg" :before-upload="onBeforeUpload"
                  :before-remove="onBeforeRemoveByUploadImg" :on-success="onSuccessByUploadImgOnly('idCardPic1','2')"
                  :on-remove="onHandleRemove('idCardPic1','2')" :on-exceed="onExceed"
                  :class="{'one-upload-disabled': (rowRoomForm.idCardPic1 && rowRoomForm.idCardPic1.length>0)}">
                  <i v-if="rowRoomForm.idCardPic1 && rowRoomForm.idCardPic1.length==0" class="el-icon-plus"></i>
                </el-upload>
                <!-- <p>（人像面）</p> -->
              </div>
            </div>
          </el-form-item>
          <el-form-item label="是否有手机号码" prop="havePhone" v-if="rowRoomForm.haveCert=='1'" key="rowRoomK6">
            <el-select v-model="rowRoomForm.havePhone" placeholder="请选择" disabled>
              <el-option v-for="item in havePhoneList" :key="item.value" :label="item.label" :value="item.value">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="登录密码" v-if="rowRoomForm.havePhone=='0' && rowRoomForm.haveCert=='1'" prop="passWord"
            key="rowRoomK7">
            <div class="ipnut-box">
              <el-input :type="rowRoomForm.passWordType" clearable @focus="passWordFocus()"
                :disabled="rowRoomForm.certifiedFlage" v-model="rowRoomForm.passWord" style="width:87%!important;">
              </el-input>
              <p class="tip-right">房客使用证件号码（账号）和登录密码登录房客端，默认密码可修改，密码为6~12位含大小写字母和数字组成</p>
            </div>
          </el-form-item>
          <el-form-item v-if="rowRoomForm.havePhone=='1' || rowRoomForm.haveCert==='0'" label="房客电话" prop="phoneNum"
            key="rowRoomK8">
            <el-input v-model.trim="rowRoomForm.phoneNum" placeholder="房客电话" clearable maxlength="11"
              :disabled="rowRoomForm.certifiedFlage"></el-input>
          </el-form-item>
          <div v-for="(item, index) in cohabitantList" :key="index">
            <!-- 在租同住人才能修改信息 -->
            <template>
              <el-form-item :label="'同住人'+(index+1)+'信息'" class="label-title">
              </el-form-item>
              <el-form-item label="姓名">
                <el-input type="text" v-model="item.realName" clearable :disabled="item.certifiedFlage"></el-input>
              </el-form-item>
              <el-form-item label="性别">
                <el-select v-model="item.gender" :disabled="item.certifiedFlage">
                  <el-option v-for="item in genderList" :key="item.value" :label="item.label" :value="item.value">
                  </el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="学号/职工号" v-if="apartmentType=='7' && item.idType!=='7'">
                <el-input type="text" v-model="item.studentCode" clearable></el-input>
              </el-form-item>
              <el-form-item label="是否有证件">
                <el-select v-model="item.haveCert" disabled>
                  <el-option v-for="item in haveCertList" :key="item.value" :label="item.label" :value="item.value">
                  </el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="证件类型" v-if="item.haveCert=='1'">
                <el-select v-model="item.idType" placeholder="请选择" clearable :disabled="item.certifiedFlage">
                  <el-option v-for="item in certTypeOptions" :key="item.value" :label="item.label" :value="item.value"
                    v-show="item.value!='7' || (apartmentCode && apartmentType=='7' && item.value=='7')">
                  </el-option>
                </el-select>
              </el-form-item>
              <el-form-item :label="item.idType=='7'?'学号/职工号':'证件号码'" v-if="item.haveCert=='1'">
                <el-input type="text" v-model="item.idCardNo" clearable :disabled="item.certifiedFlage"></el-input>
              </el-form-item>
              <el-form-item label="上传证件照片" v-if="isUploadPic=='1'">
                <div class="picCont">
                  <div class="pic-lt">
                    <el-upload class="avatar-uploader" accept="image/*" list-type="picture-card"
                      :action="UPLOAD_URL + '/user-service/weChat/uploadPic'" :file-list="item.idCardPic1"
                      :on-preview="onPreviewByUploadImg" :before-upload="onBeforeUpload"
                      :before-remove="onBeforeRemoveByUploadImg"
                      :on-success="onSuccessByUploadImgOnly('idCardPic1','2',index)"
                      :on-remove="onHandleRemove('idCardPic1','2',index)" :on-exceed="onExceed"
                      :class="{'one-upload-disabled': (item.idCardPic1 && item.idCardPic1.length>0)}">
                      <i v-if="item.idCardPic1 && item.idCardPic1.length==0" class="el-icon-plus"></i>
                    </el-upload>
                  </div>
                </div>
              </el-form-item>
              <el-form-item label="是否有手机号码" v-if="item.haveCert=='1'">
                <el-select v-model="item.havePhone" placeholder="请选择" disabled>
                  <el-option v-for="item in havePhoneList" :key="item.value" :label="item.label" :value="item.value">
                  </el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="登录密码" v-if="item.havePhone=='0' && item.haveCert=='1'">
                <div class="ipnut-box">
                  <el-input :type="item.passWordType" clearable @focus="passWordFocus(index)" v-model="item.passWord"
                    style="width:87%!important;" :disabled="item.certifiedFlage"></el-input>
                  <p class="tip-right">房客使用证件号码（账号）和登录密码登录房客端，默认密码可修改，密码为6~12位含大小写字母和数字组成</p>
                </div>
              </el-form-item>
              <el-form-item v-if="item.havePhone=='1' || item.haveCert=='0'" label="房客电话">
                <el-input v-model.trim="item.phoneNum" placeholder="房客电话" clearable maxlength="11"
                  :disabled="item.certifiedFlage">
                </el-input>
              </el-form-item>
            </template>
          </div>
        </el-form>
        <div style="text-align: center;">
          <!-- <el-button @click="onRester()">取消</el-button> -->
          <el-button type="primary" @click="onSubmit('ruleForm')">确定</el-button>
        </div>
      </div>
    </el-dialog>
  </div>
</template>
<script>
  import Vue from "vue";
  let tenantidType = '1'
  let validID = (rule, value, callback) => {
    if (!Vue.prototype.checkIdCard(value)) {
      callback(new Error("身份证号码不正确"));
    } else {
      callback();
    }
  };
  let validMobile = (rule, value, callback) => {
    let reg = /^1[3-9](\d){9}$/;
    if (!reg.test(value)) {
      callback(new Error("手机号码格式不对"));
    } else {
      callback();
    }
  };
  let validPsword = (rule, value, callback) => {
    let reg = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[^]{6,12}$/;
    let reg2 = /[^A-z0-9`~!@#$%^&*()_\-+=<>?:"{}|,.\/;'\\[\]·]/g;
    if (reg2.test(value)) {
      callback(new Error("密码格式不对(只能包含大小写字母、数字、英文符号)"));
    } else if (!reg.test(value)) {
      callback(new Error("密码格式不对(密码为6~12位含大小写字母和数字组成)"));
    } else {
      callback();
    }
  };
  let valididCardNo = (rule, value, callback) => {
    let reg = /^[A-z0-9]{4,24}$/;
    let reg2 = /^[1-9]\d{5}(18|19|([23]\d))\d{2}((0[1-9])|(10|11|12))(([0-2][1-9])|10|20|30|31)\d{3}[0-9Xx]$/;
    if (tenantidType == '7') {
      if (!value) {
        callback(new Error("请输入学号/职工号"));
      } else if (!reg.test(value)) {
        callback(new Error("学号/职工号格式不对"));
      } else {
        callback();
      }
    } else if (tenantidType == '1') {
      if (!value) {
        callback(new Error("请输入证件号码"));
      } else if (!Vue.prototype.checkIdCard(value)) {
        callback(new Error("证件号码格式不对"));
      } else {
        callback();
      }
    } else {
      if (!value) {
        callback(new Error("请输入证件号码"));
      } else if (!reg.test(value)) {
        callback(new Error("证件号码格式不对"));
      } else {
        callback();
      }
    }

  };
  let validName = (rule, value, callback) => {
    if (value == "" || value == undefined) {
      callback(new Error("请输入房客姓名"));
    } else {
      //房客姓名支持输入中英文字母，汉字，1~16个字符
      // let reg = /^[a-zA-Z\u4e00-\u9fa5]{1,16}$/;
      let reg = /^[a-zA-Z\u4e00-\u9fa5]{1,16}$/;
      if (!reg.test(value)) {
        callback(new Error("房客姓名格式不对"));
      } else {
        callback();
      }
    }
  };
  let validStudentCode = (rule, value, callback) => {
    let reg = /^[A-Za-z0-9]{4,24}$/;
      if(value){
        if (!reg.test(value)) {
          callback(new Error("学号/职工号格式不正确"));
        } else {
          callback();
        }
      }else {
        callback();
      }
  }
  export default {
    data() {
      let _this = this;
      return {
        isShowCohabitantDelBtn: false,
        isShowEditBtn: false,
        isPMS: false,
        showDeleteTenantBtn: false,
        leaseState: '',
        userType: '',
        contractId: '',
        userId: '',
        isUploadPic: 0, //是否上传身份照片
        isKey: 0, //是否实名发钥匙
        apartmentCode: '',
        apartmentType: 0,
        loading: false,
        searchTenantOptions: [],
        platoonRoomVisible: false,
        addRoommateVisible: false,
        addRoommateForm: {
          realName: '',
          phoneNum: '',
          idType: '1',
          idCardNo: '',
          idCardPic1: [],
          havePhone: '1',
          passWord: 'Pw123456',
          pwSearchFlag: false,
          haveCert: '1',
          gender: '1',
          studentCode: '',
        },
        rowRoomForm: {
          realName: '',
          phoneNum: '',
          idType: '',
          idCardNo: '',
          idCardPic1: [],
          havePhone: '1',
          passWord: '',
          haveCert: '1',
          certifiedFlage: false,
          pwSearchFlag: false,
          gender: '1',
          studentCode: '',
        },
        signatoryData: {
          realName: '',
          phoneNum: '',
          idType: '',
          idCardNo: '',
          idCardPic1: [],
          havePhone: '1',
          passWord: '',
          haveCert: '1',
          headImg: require('../../assets/boy_grey.png'),
          gender: '1',
          studentCode: '',
        },
        roommateDataList: [],
        genderList: [
            {
              value: "1",
              label: "男"
            },
            {
              value: "2",
              label: "女"
            }
        ],
        havePhoneList: [{
            value: "0",
            label: "无"
          },
          {
            value: "1",
            label: "有"
          }
        ],
        haveCertList: [{
            value: "0",
            label: "无"
          },
          {
            value: "1",
            label: "有"
          }
        ],
        addRoommateRules: {
          realName: [{
            required: true,
            // message: "请输入房客姓名",
            validator: validName,
            trigger: ['change', 'blur']
          }],
          haveCert: [{
            required: true,
            message: "请选择是否有证件",
            trigger: ['change']
          }],
          gender: [{
            required: true,
            message: "请选择性别",
            trigger: ['change']
          }],
          phoneNum: [{
            required: true,
            validator: validMobile,
            // message: "请输入房客电话",
            trigger: ['change', 'blur']
          }],
          passWord: [{
            required: true,
            validator: validPsword,
            trigger: ['change', 'blur']
          }],
          idType: [{
            required: true,
            message: "请选择证件类型",
            trigger: "change"
          }],
          idCardNo: [{
            required: true,
            validator: valididCardNo,
            trigger: ['change', 'blur']
          }],
          idCardPic1: [{
            required: true,
            message: "请上传证件照片",
          }],
          havePhone: [{
            required: true,
            message: "请选择是否有手机号码",
            trigger: "change"
          }],
          studentCode: [{
              required: false,
              trigger: ['change'],
              validator: validStudentCode,
          }],
        },
        rules: {
          gender: [{
            required: true,
            message: "请选择性别",
            trigger: ['change']
          }],
          realName: [{
            required: true,
            validator: validName,
            // message: "请输入房客姓名",
            trigger: ['change', 'blur']
          }],
          phoneNum: [{
            required: true,
            validator: validMobile,
            // message: "请输入房客电话",
            trigger: ['change', 'blur']
          }],
          passWord: [{
            required: true,
            validator: validPsword,
            trigger: ['change', 'blur']
          }],
          idType: [{
            required: true,
            message: "请选择证件类型",
            trigger: "change"
          }],
          idCardNo: [{
            required: true,
            validator: valididCardNo,
            trigger: ['change', 'blur']
          }],
          idCardPic1: [{
            required: true,
            message: "请上传证件照片",
          }],
          havePhone: [{
            required: true,
            message: "请选择是否有手机号码",
            trigger: "change"
          }],
          studentCode: [{
              required: false,
              trigger: ['change'],
              validator: validStudentCode,
          }],
        },
        cohabitantList: [{
          realName: "",
          phoneNum: "",
          idType: "1",
          idCardNo: "",
          idCardPic1: [],
          havePhone: '1',
          passWord: 'Pw123456',
          passWordType: 'text',
          pwSearchFlag: false,
          userId: '',
          gender: '1',
          studentCode: '',
        }, ],
        queryData: {}
      };
    },
    created() {
      this.userType = this.cache.getLS("userInfo")["roleType"];
    },
    activated() {
      console.log("lease-tenant-detail activated!!");

      this.queryData = this.$route.query;
      console.log(this.queryData,'this.queryData---');
      if (this.queryData.leaseType == 4) {
        this.isPMS = true
      } else {
        this.isPMS = false
      }
      this.userType = this.cache.getLS("userInfo")["roleType"];
      this.addRoommateForm = {
          realName: '',
          phoneNum: '',
          idType: '1',
          idCardNo: '',
          idCardPic1: [],
          havePhone: '1',
          passWord: 'Pw123456',
          pwSearchFlag: false,
          haveCert: '1',
          gender: '1',
          studentCode: '',
        },
        this.rowRoomForm = {
          realName: '',
          phoneNum: '',
          idType: '',
          idCardNo: '',
          idCardPic1: [],
          havePhone: '1',
          passWord: '',
          haveCert: '1',
          certifiedFlage: false,
          pwSearchFlag: false, //接口查询密码回来的标识
          gender: '1',
          studentCode: '',
        },
        this.signatoryData = {
          realName: '',
          phoneNum: '',
          idType: '',
          idCardNo: '',
          idCardPic1: [],
          havePhone: '1',
          passWord: '',
          haveCert: '1',
          headImg: require('../../assets/boy_grey.png'),
          gender: '1',
          studentCode: '',
        };
      this.roommateDataList = [];
      this.leaseState = this.cache.get('leaseState');
      console.log('this.leaseState:', this.leaseState)
      // if (['0', '1', '3'].indexOf(this.leaseState) == -1) { //待支付 已取消  取消预定 不请求房客信息
      //   this.getInitData();
      // }
      this.getInitData();
      if(this.getLandlordFlage() || this.getHousekeeperPlanAndQuitFlage()) {
        this.isShowCohabitantDelBtn = true;
      }else {
        this.isShowCohabitantDelBtn = false;
      }

    },
    methods: {
      addRoommateHavePhoneChange() {
        if (this.addRoommateForm.havePhone == '0') {
          this.addRoommateForm.passWord = 'Pw123456';
        } else if (this.addRoommateForm.havePhone == '1') {
          this.addRoommateForm.phoneNum = '';
        }
      },
      addRoommateHCertChange() {
        if (this.addRoommateForm.haveCert == '0') {
          this.addRoommateForm.idType = '1';
          this.addRoommateForm.idCardNo = '';
          this.addRoommateForm.havePhone = '1';
        }
      },
      deleteBtn(item) {
        let _this = this;
        _this.$confirm("确定要删除同住人吗?请先与房客确认删除同住人", "删除同住人", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning"
          })
          .then(() => {
            let dto = {
              roomId:this.$route.query.roomId,
              userId:item.userId,
              type: item.contractType,
            }
            _this.post("tenant-service/v1/leaseRoomUser/query-cohabit",dto).then(res=>{
              if(res.isCohabit == true){
                let dto = {
                  userId: item.userId,
                  contractId: this.$route.query.validContractId,
                  leaseId: this.$route.query.leaseId,
                }
                _this.post("tenant-service/leaseroomuser/delCohabitant", dto)
                  .then(function (res) {
                    _this.getInitData();
                    _this.$message({
                      message: "删除成功",
                      type: "success"
                    });
                  })
                  .catch(function (res) {
                    _this.$message({
                      message: res.data.msg,
                      type: "warning"
                    });
                  });
              }else{
                _this.$message({
                  message: "当前租约入住人员信息已被更新，请重新操作！",
                  type: "warning"
                });
                this.getInitData();
              }
            })

            
          })
          .catch(function () {});
      },
      deleteTenant(signatoryData) {
        let _this = this;
        _this.$confirm("确定要删除该签约主体人吗?删除后，最先添加的同住人成为签约主体人。","删除签约主体人",{
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning"
        }).then(() => {
          let dto = {
              roomId:this.$route.query.roomId,
              userId:signatoryData.userId,
              type: signatoryData.contractType,
            }
            _this.post("tenant-service/v1/leaseRoomUser/query-cohabit",dto).then(res=>{
              if(res.isCohabit == true){
                let dto = {
                  relateBizId: this.$route.query.leaseId,
                }
                _this.post('/tenant-service/v1/lease/agreeDeleteOne',dto).then(res => {
                    _this.getInitData();
                    _this.$message({
                        message: "删除成功",
                        type: "success"
                    });
                }).catch(function (res) {
                    this.getInitData();
                  });
              }else{
                _this.$message({
                  message: "当前租约入住人员信息已被更新，请重新操作！",
                  type: "warning"
                });
                this.getInitData();
              }
            })
        }).catch(() => {
        })
        
      },
      //根据手机号码查询是否为认证同住人
      searchRealphone(queryString) {
        var results = [];
        let phoneReg = /^1[3-9](\d){9}$/;
        if (this.addRoommateForm.haveCert == '0') {
          return;
        }
        if (queryString == "") {
          //是否已认证
          this.addRoommateForm.isCertified = false;
          this.addRoommateForm.searchPhoneFlage = false;
          this.addRoommateForm.searchCertChangePhoneFlag = false;
          this.addRoommateForm.realName = '';
          this.addRoommateForm.phoneNum = '';
          this.addRoommateForm.idType = '';
          this.addRoommateForm.gender = '1';
          this.addRoommateForm.studentCode = '';
          // tenantCertType = '';
          this.addRoommateForm.idCardNo = '';
          if (this.isKey == '1') {
            this.addRoommateForm.idType = '1';
          }
          return;
        }
        if (phoneReg.test(queryString)) {
          this.post("user-service/user/config/query/info/" + queryString + "/mobile/account?filter=true", {}, {
            isNotShowError: true,
            isUseResponse: true,
          }).then(data => {
            if (data.data.code == 0) {
              let result = data.data.data;
              let res = result.user;
              //是否已认证
              if (result.realState) //已实名
              {
                this.addRoommateForm.isCertified = true;
                this.addRoommateForm.searchPhoneFlage = true;
                // this.lastAuthentication = true;
                this.addRoommateForm.idType = res.idType + '';
                // tenantCertType = res.idType + '';
                this.addRoommateForm.idCardNo = res.idCardNo;
              } else {
                if (this.isKey == '1') {
                  return;
                }
                this.lastAuthentication = false;
                if (result.accounts && result.accounts[0]) {
                  if (result.accounts[0].category.indexOf('student') != -1) {
                    result.accounts[0].category = 'student';
                  }
                  this.addRoommateForm.idCardNo = result.accounts[0].account;
                  this.addRoommateForm.idType = this.cardStrToNum(result.accounts[0].category);
                  // tenantCertType =this.cardStrToNum(result.accounts[0].category);
                }else{
                  this.addRoommateForm.idType = res.idType + '';
                  this.addRoommateForm.idCardNo = res.idCardNo;
                }
              }
              this.addRoommateForm.gender = res.gender ? res.gender.toString() : '1';
              this.addRoommateForm.realName = res.realName;
              this.addRoommateForm.phoneNum = res.phoneNum;
              this.addRoommateForm.studentCode = result.userInfoConfigMap.student_no;
              if (res.phoneNum) {
                this.addRoommateForm.havePhone = '1';
                this.addRoommateForm.searchCertChangePhoneFlag = true;
              } else {
                this.addRoommateForm.passWord = 'Pw123456';
                this.addRoommateForm.pwSearchFlag = true;
                this.addRoommateForm.searchCertChangePhoneFlag = false;
              }
            } else {
              if (this.lastAuthentication) {
                this.addRoommateForm.gender = '1';
                this.addRoommateForm.idType = '';
                // tenantCertType = '';
                this.addRoommateForm.idCardNo = '';
                this.addRoommateForm.realName = '';
                this.addRoommateForm.studentCode = '';
                this.addRoommateForm.searchCertChangePhoneFlag = false;
                this.lastAuthentication = false;
              }
            }
          }).catch(err => {
            if (this.lastAuthentication) {
              this.addRoommateForm.gender = '1';
              this.addRoommateForm.idType = '';
              // tenantCertType = '';
              this.addRoommateForm.idCardNo = '';
              this.addRoommateForm.realName = '';
              this.addRoommateForm.studentCode = '';
              this.addRoommateForm.searchCertChangePhoneFlag = false;
              this.lastAuthentication = false;
            }
            //是否已认证
            this.addRoommateForm.isCertified = false;
            this.addRoommateForm.searchPhoneFlage = false;
          })
        }
      },
      //同住人查询证件号码
      searchIdCardNoData(obj) {
        let queryString = obj.idCardNo
        if (queryString == "") {
          return;
        }
        this.post('user-service/user/config/query/info/' + obj.idCardNo + '/' + this.certTypeString[this.addRoommateForm
          .idType] + '/account?filter=true', {}, {
          isNotShowError: true,
          isUseResponse: true
        }).then(data => {
          if (data.data.code == 0) {
            let result = data.data.data;
            let res = result.user;
            if (result.realState) //实名认证
            {
              this.addRoommateForm.idType = res.idType + "" || "";
              // tenantCertType = res.idType + "" || "";
              this.addRoommateForm.idCardNo = res.idCardNo || "";
              this.addRoommateForm.isCertified = true;
              this.addRoommateForm.searchCertFlage = true;
              this.lastAuthentication = true;
            } else {
              if (this.isKey == '1') {
                return;
              }
              this.addRoommateForm.isCertified = false;
              this.addRoommateForm.searchCertFlage = false;
              this.lastAuthentication = false;
            }
            this.addRoommateForm.gender = res.gender.toString() || "1";
            this.addRoommateForm.realName = res.realName || "";
            this.addRoommateForm.phoneNum = res.phoneNum || "";
            this.addRoommateForm.studentCode = result.userInfoConfigMap.student_no || "";
            this.addRoommateForm.pwSearchFlag = true;
            if (res.phoneNum) {
              this.addRoommateForm.havePhone = '1';
              this.addRoommateForm.searchCertChangePhoneFlag = true;
            } else {
              this.addRoommateForm.havePhone = '0';
              this.addRoommateForm.passWord = 'Pw123456';
              this.addRoommateForm.passWordType = 'password';
              this.addRoommateForm.searchCertChangePhoneFlag = false;
            }
          } else {
            if (this.lastAuthentication) {
              this.addRoommateForm.gender = "1";
              this.addRoommateForm.realName = "";
              this.addRoommateForm.phoneNum = "";
              this.addRoommateForm.searchCertChangePhoneFlag = false;
              this.addRoommateForm.isCertified = false;
              this.addRoommateForm.searchCertFlage = false;
              this.lastAuthentication = false;
              this.addRoommateForm.studentCode = "";
            }
          }
        }).catch(err => {
          //是否已认证
          if (this.lastAuthentication) {
            this.addRoommateForm.gender = "1";
            this.addRoommateForm.realName = "";
            this.addRoommateForm.phoneNum = "";
            this.addRoommateForm.searchCertChangePhoneFlag = false;
            this.lastAuthentication = false;
            this.addRoommateForm.studentCode = "";
          }
          this.addRoommateForm.isCertified = false;
          this.addRoommateForm.searchCertFlage = false;
        })
      },
      //根据证件号码查询是否为认证
      searchIdCardNo(obj, flag) {
        let queryString = obj.idCardNo
        if (queryString == "" && this.addRoommateForm.searchCertFlage) {
          //是否已认证
          this.addRoommateForm.isCertified = false;
          this.addRoommateForm.searchCertFlage = false;
          this.lastAuthentication = false;
          this.addRoommateForm.searchCertChangePhoneFlag = false;
          this.addRoommateForm.realName = '';
          this.addRoommateForm.studentCode = '';
          this.addRoommateForm.phoneNum = '';
          this.addRoommateForm.havePhone = '1';
          this.addRoommateForm.passWord = 'Pw123456';
          this.addRoommateForm.gender = '1';
          return;
        }
        if (this.checkIdCard(queryString) && flag == '1') //身份证
        {
          this.searchIdCardNoData(obj)
        } else if (flag == '2') //失去焦点
        {
          this.searchIdCardNoData(obj)
        }
      },
      addRoommateSubmit() {
        let _this = this;
        this.$refs['addRoommateForm'].validate(valid => {
          if (valid) {
            let dto = {
              roomId: this.queryData.roomId,
              userList: [{
                gender: this.addRoommateForm.gender ? this.addRoommateForm.gender : '1',
                userName: this.addRoommateForm.realName,
                cardType: this.addRoommateForm.haveCert == '1' ? this.addRoommateForm.idType : '',
                idcardno: this.addRoommateForm.haveCert == '1' ? this.addRoommateForm.idCardNo : '',
                phoneNumber: this.addRoommateForm.phoneNum,
                isPlanUser: 1,
                certifiedFlage: this.addRoommateForm.isCertified,
                idCardPic1: this.addRoommateForm.idCardPic1.length > 0 ? this.addRoommateForm.idCardPic1[0]
                  .url : '',
                havePhone: this.addRoommateForm.havePhone,
                password: (this.addRoommateForm.havePhone == '1' || this.addRoommateForm.pwSearchFlag ||
                    this.addRoommateForm.haveCert == '0') ?
                  '' : this.AES
                  .encrypt(this.addRoommateForm.passWord)
              }],
              idCardPic1: "",
              leaseId: this.$route.query.leaseId,
              // "contractId": this.$route.params.id
            }
            if (this.apartmentCode && this.addRoommateForm.idType == '7') {
              dto.userList[0].code = this.apartmentCode
            }
            if (this.apartmentType == '7') {
              if(this.addRoommateForm.idType == '7') {
                dto.userList[0].studentCode = this.addRoommateForm.idCardNo
              }else{
                dto.userList[0].studentCode = this.addRoommateForm.studentCode
              }
            }
            if (this.queryData.leaseType == '1') {
              // dto.rentId = this.$route.params.id.substring(2, this.$route.params.id.length);
              dto.rentId = this.$route.params.id;
            } else if (this.queryData.leaseType == '0' || this.queryData.leaseType == '2') {
              dto.contractId = this.$route.query.validContractId;
              // dto.contractId =  this.$route.params.id;
            }
            this.post('landlord-service/room/addCohabitUser', dto, {
              isUseResponse: true,
              operatorFlage: true,
              operateName: '添加同住人',
              isNotShowError: true
            }).then(data => {
              if (data.data.code == 0) {
                this.getInitData();
                this.$message({
                  showClose: true,
                  message: "添加同住人成功",
                  type: "success"
                });
                this.addRoommateVisible = false;
              }
            }).catch(err => {
              if(err.data.code == 999226){
                _this.$alert(err.data.msg, '学号/职工号重复', {
                  confirmButtonText: '确定',
                  callback: action => {
                    _this.alertFlag = false
                  }
                });
              }else{
                this.$message({
                  showClose: true,
                  message: err.data.msg,
                  type: "warning"
                });
              }
            })
          }
        })
      },
      addRoommateBtn() {
        let _this = this;
        _this.addRoommateForm = {
          realName: '',
          phoneNum: '',
          idType: '1',
          idCardNo: '',
          idCardPic1: [],
          havePhone: '1',
          passWord: 'Pw123456',
          pwSearchFlag: false,
          haveCert: '1',
          gender: '1',
          studentCode: '',
        };
        if (_this.$refs["addRoommateForm"]) {
          _this.$nextTick(() => {
            _this.$refs["addRoommateForm"].clearValidate();
          });
        }
        _this.addRoommateVisible = true;
      },
      remoteMethod(query) {
        let phoneReg = /^1[3-9](\d){9}$/;
        if (query !== "" && (phoneReg.test(query))) {
          this.loading = true;
          setTimeout(() => {
            this.loading = false;
            this.getUserInfo(query, phoneReg.test(query));
          }, 200);
        } else {
          this.searchTenantOptions = [];
        }
      },
      selectTrigger(val) {
        let arr = val.split("|");
        this.addRoommateForm.realName = arr[0];
        this.addRoommateForm.phoneNum = arr[1];
        this.addRoommateForm.idType = arr[2];
        this.addRoommateForm.idCardNo = arr[3];
        this.addRoommateForm.gender = arr[4];
        this.addRoommateForm.studentCode = arr[5];
        if (this.addRoommateForm.phoneNum) {
          this.addRoommateForm.havePhone = '1';
          this.addRoommateForm.searchCertChangePhoneFlag = true;
        } else {
          this.addRoommateForm.havePhone = '0';
          this.addRoommateForm.passWord = 'Pw123456';
          this.addRoommateForm.pwSearchFlag = true;
          this.addRoommateForm.searchCertChangePhoneFlag = false;
        }
        if (arr.length > 1 && arr[2] == '1') {
          //选择了房客  已认证
          this.addRoommateForm.isCertified = true;
          this.addRoommateForm.searchPhoneFlage = true;
          this.addRoommateForm.searchCertFlage = true;
          this.lastAuthentication = true;

        } else {
          this.addRoommateForm.isCertified = false;
          this.addRoommateForm.searchPhoneFlage = false;
          this.addRoommateForm.searchCertFlage = false;
        }
        if (arr[2] && arr[3]) //有证件号码
        {
          this.addRoommateForm.haveCert = '1';
        } else {
          this.addRoommateForm.haveCert = '0';
        }
        if (!arr[0] && !arr[1] && !arr[2] && !arr[3]) //清空时数据重置
        {
          this.addRoommateForm.haveCert = '1';
          this.addRoommateForm.havePhone = '1';
        }
        if (this.isKey == '1') {
          this.addRoommateForm.idType = '1';
        }
      },
      getUserInfo(query, flag) {
        this.post("user-service/user/config/query/info/" + query + "/mobile/account?filter=true", {}, {
          // isNotShowError: true
        }).then(result => {
          let res = result.user;
          let cardType = '';
          let cardNo = '';
          if (result.realState) //实名认证
          {
            cardType = res.idType;
            cardNo = res.idCardNo;
          } else {
            if (this.isKey == '1') {
              return;
            }
            if (result.accounts && result.accounts[0]) {
              cardNo = result.accounts[0].account;
              cardType = this.cardStrToNum(result.accounts[0].category);
            }else{
              cardType = res.idType;
              cardNo = res.idCardNo;
            }
          }
          let genderStr = res.gender == '1' ? '男' : '女';
          this.searchTenantOptions = [{
            value: (res.realName || "") +
              "|" +
              (res.phoneNum || "") +
              "|" +
              (cardType || "") +
              "|" +
              (cardNo || "")+
              "|" +
              (res.gender || "")+
              "|" +
              (this.apartmentType == '7'?(result.userInfoConfigMap.student_no?result.userInfoConfigMap.student_no:""):"" || ""),
            label: (res.realName || "") +
              "|" +
              (res.phoneNum || "") +
              "|" +
              (cardType || "") +
              "|" +
              (cardNo || "")+
              "|" +
              (genderStr || "")+
              "|" +
              (this.apartmentType == '7'?(result.userInfoConfigMap.student_no?result.userInfoConfigMap.student_no:""):"" || "")
          }];
        });
      },
      idTypeChange(val) {
        tenantidType = val;
      },
      passWordFocus(index) {
        if (index == undefined) {
          this.rowRoomForm.passWord = '';
          this.rowRoomForm.pwSearchFlag = false;
          this.$forceUpdate();
        } else {
          this.cohabitantList[index].passWord = '';
          this.cohabitantList[index].pwSearchFlag = false;
        }
      },
      onSubmit(formName) {
        console.log('rowRoomForm', this.rowRoomForm)
        console.log('cohabitantList', this.cohabitantList)
        let _this = this;
        if (this.isKey == '1' && this.rowRoomForm.idType != '1') {
          return this.$message({
            showClose: true,
            message: "房客信息的证件类型必须为身份证",
            type: "warning"
          });
        }
        this.$refs[formName].validate(valid => {
          if (valid) {
            for (let i = 0; i < this.cohabitantList.length; i++) {
              let room = JSON.parse(JSON.stringify(this.cohabitantList[i]));
              if (this.isKey == '1' && room.idType != '1') {
                return this.$message({
                  showClose: true,
                  message: "序号为" + (i + 1) + "中的同住人的证件类型必须为身份证",
                  type: "warning"
                });
              }
              if (!room.realName) {
                return this.$message({
                  showClose: true,
                  message: "请输入序号为" + (i + 1) + "中的同住人姓名",
                  type: "warning"
                });
              }
              let realNameReg = /^[a-zA-Z\u4e00-\u9fa5]{1,16}$/;
              if (room.realName && !realNameReg.test(room.realName)) {
                return this.$message({
                  showClose: true,
                  message: "序号为" + (i + 1) + "中的同住人姓名格式错误",
                  type: "warning"
                });
              }

              if (room.haveCert == '1') {
                if (room.idCardNo == "") {
                  return _this.$message({
                    showClose: true,
                    message: "请输入序号为" + (i + 1) + "中的同住人" + (room.idType == "7" ? "学号/职工号" : "证件号码"),
                    type: "warning"
                  });
                } else {
                  if (room.idType == "1") {
                    let reg =
                      /^[1-9]\d{5}(18|19|([23]\d))\d{2}((0[1-9])|(10|11|12))(([0-2][1-9])|10|20|30|31)\d{3}[0-9Xx]$/;
                    if (!reg.test(room.idCardNo)) {
                      return this.$message({
                        showClose: true,
                        message: "请在序号为" + (i + 1) + "中的输入正确同住人证件号码",
                        type: "warning"
                      });
                    }
                  } else if (room.idType == "7") {
                    let reg = /^[A-z0-9]{4,24}$/;
                    if (!reg.test(room.idCardNo)) {
                      return this.$message({
                        showClose: true,
                        message: "请在序号为" + (i + 1) + "中的输入正确同住人学号/职工号",
                        type: "warning"
                      });
                    }
                  } else {
                    let reg = /^[A-z0-9]{4,24}$/;
                    if (!reg.test(room.idCardNo)) {
                      return this.$message({
                        showClose: true,
                        message: "请在序号为" + (i + 1) + "中的输入正确同住人证件号码",
                        type: "warning"
                      });
                    }
                  }
                }
              }
              if(this.apartmentType == '7' && room.idType!=='7') {
                  if(room.studentCode){
                    let reg = /^[A-Za-z0-9]{4,24}$/;
                    if (!reg.test(room.studentCode)) {
                      return this.$message({
                        showClose: true,
                        message: "请在序号为" + (i + 1) + "中的输入正确同住人学号/职工号",
                        type: "warning"
                      });
                    }
                  }
                }
              if (room.idCardPic1.length !== 1 && this.isUploadPic == '1') {
                return this.$message({
                  showClose: true,
                  message: "请在序号为" + (i + 1) + "中的上传证件照片",
                  type: "warning"
                });
              }
              if (room.havePhone == '1') {
                if (room.phoneNum == "") {
                  return this.$message({
                    showClose: true,
                    message: "请输入序号为" + (i + 1) + "中的同住人电话",
                    type: "warning"
                  });
                } else {
                  let reg = /^1[3-9](\d){9}$/;
                  if (!reg.test(room.phoneNum)) {
                    return this.$message({
                      showClose: true,
                      message: "请在序号为" + (i + 1) + "中的输入正确同住人电话",
                      type: "warning"
                    });
                  }
                }
              } else if (room.havePhone == '0') {
                if (room.passWord == "") {
                  return this.$message({
                    showClose: true,
                    message: "请输入序号为" + (i + 1) + "中的登录密码",
                    type: "warning"
                  });
                } else {
                  let reg = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[^]{6,12}$/;
                  let reg2 = /[^A-z0-9`~!@#$%^&*()_\-+=<>?:"{}|,.\/;'\\[\]·]/g;
                  if (reg2.test(room.passWord)) {
                    return this.$message({
                      showClose: true,
                      message: "请在序号为" + (i + 1) + "中的输入正确登录密码",
                      type: "warning"
                    });
                  } else if (!reg.test(room.passWord)) {
                    return this.$message({
                      showClose: true,
                      message: "请在序号为" + (i + 1) + "中的输入正确登录密码",
                      type: "warning"
                    });
                  }
                }

              }
            }
            let dtoDatas = [];
            let dto = this.cohabitantList;
            for (let i = 0; i < dto.length; i++) {
              let objData = {
                  userId: dto[i].userId,
                  contractId: this.$route.params.id,
                  realName: dto[i].realName,
                  gender: dto[i].gender ? dto[i].gender :'1',
                  idType: dto[i].haveCert == '1' ? dto[i].idType : null,
                  phoneNum: dto[i].havePhone == '1' ? dto[i].phoneNum : null,
                  idCardNo: dto[i].haveCert == '1' ? dto[i].idCardNo : null,
                  cardUrl: dto[i].idCardPic1.length > 0 ? dto[i].idCardPic1[0].url : null,
                  // cardUrl: dto[i].cardUrl,
                  havePhone: dto[i].havePhone,
                  leaseId: this.$route.query.leaseId,
                  // password: (dto[i].havePhone == '1' || dto[i].pwSearchFlag || dto[i].haveCert == '0') ? '' :
                  //   _this.AES.encrypt(dto[i].passWord),
                }
              if (dto[i].realName != "" && !dto[i].certifiedFlage) //在租并且未实名
              {
                if (dto[i].havePhone == '0' && !dto[i].pwSearchFlag && dto[i].haveCert == '1') {
                  objData.password = _this.AES.encrypt(dto[i].passWord);
                }
                if (this.apartmentCode && dto[i].idType == '7') {
                  objData.code = this.apartmentCode
                }
                if (this.apartmentType == '7') {
                  if(dto[i].idType == '7'){
                    objData.studentCode = dto[i].idCardNo
                  }else{
                    objData.studentCode = dto[i].studentCode
                  }
                }
                if (this.queryData.leaseType == '1') {
                  objData.contractId = this.$route.params.id.substring(2, this.$route.params.id.length);
                } else if (this.queryData.leaseType == '0' || this.queryData.leaseType == '2') {
                  objData.contractId = this.$route.query.validContractId;
                }
              }
              if(dto[i].certifiedFlage){
                if (dto[i].havePhone == '0' && !dto[i].pwSearchFlag && dto[i].haveCert == '1') {
                  objData.password = _this.AES.encrypt(dto[i].passWord);
                }
                if (this.apartmentCode && dto[i].idType == '7') {
                  objData.code = this.apartmentCode
                }
                if (this.apartmentType == '7') {
                  if(dto[i].idType == '7'){
                    objData.studentCode = dto[i].idCardNo
                  }else{
                    objData.studentCode = dto[i].studentCode
                  }
                }
                if (this.queryData.leaseType == '1') {
                  objData.contractId = this.$route.params.id.substring(2, this.$route.params.id.length);
                } else if (this.queryData.leaseType == '0' || this.queryData.leaseType == '2') {
                  objData.contractId = this.$route.query.validContractId;
                }
              }
              dtoDatas.push(objData);
            }
            for (let h = 0; h < dtoDatas.length; h++) {
              if (this.rowRoomForm.phoneNum && dtoDatas[h].phoneNum && this.rowRoomForm.phoneNum ==
                dtoDatas[h].phoneNum) {
                return this.$message({
                  showClose: true,
                  message: "同住人电话与房客电话相同",
                  type: "warning"
                });
              } else if (this.rowRoomForm.idCardNo && dtoDatas[h].idCardNo && this.rowRoomForm.idCardNo == dtoDatas[
                  h].idCardNo) {
                return this.$message({
                  showClose: true,
                  message: "同住人" + (dtoDatas[h].idType == "7" ? "学号/职工号" : "证件号码") + "与房客" + (this.rowRoomForm
                    .idType == "7" ? "学号/职工号" : "证件号码") + "相同",
                  type: "warning"
                });
              }
            }
            for (let j = 0; j < dtoDatas.length; j++) {
              if (
                dtoDatas[j] &&
                dtoDatas[j + 1] &&
                dtoDatas[j].phoneNum &&
                dtoDatas[j + 1].phoneNum &&
                dtoDatas[j].phoneNum == dtoDatas[j + 1].phoneNum
              ) {
                return this.$message({
                  showClose: true,
                  message: "序号" + (j + 1) + "与" + (j + 2) + "中的同住人电话相同",
                  type: "warning"
                });
              } else if (
                dtoDatas[j] &&
                dtoDatas[j + 1] &&
                dtoDatas[j].idCardNo &&
                dtoDatas[j].idCardNo == dtoDatas[j + 1].idCardNo
              ) {
                return this.$message({
                  showClose: true,
                  message: "序号" + (j + 1) + "与" + (j + 2) + "中的同住人" + (dtoDatas[j].idType == '7' ? '学号/职工号' :
                    '证件号码') + "相同",
                  type: "warning"
                });
              }
            }
            if (!this.rowRoomForm.certifiedFlage) {
              dtoDatas.unshift({
                userId: this.userId,
                gender: this.rowRoomForm.gender ? this.rowRoomForm.gender : '1',
                realName: this.rowRoomForm.realName,
                phoneNum: this.rowRoomForm.havePhone == '1' ? this.rowRoomForm.phoneNum : null,
                idType: this.rowRoomForm.haveCert == '1' ? this.rowRoomForm.idType : null,
                idCardNo: this.rowRoomForm.haveCert == '1' ? this.rowRoomForm.idCardNo : null,
                idCardPic1: this.rowRoomForm.idCardPic1.length > 0 ? this.rowRoomForm.idCardPic1[0].url : null,
                havePhone: this.rowRoomForm.havePhone,
                leaseId: this.$route.query.leaseId,
                // passWord: (this.rowRoomForm.havePhone == '1' || this.rowRoomForm.pwSearchFlag || this.rowRoomForm
                //     .haveCert == '0') ? '' : _this.AES
                //   .encrypt(this.rowRoomForm.passWord),
              })
              if (this.rowRoomForm.havePhone == '0' && !this.rowRoomForm.pwSearchFlag && this.rowRoomForm
                .haveCert == '1') {
                dtoDatas[0].passWord = _this.AES.encrypt(this.rowRoomForm.passWord)
              }
              if (this.apartmentCode && this.rowRoomForm.idType == '7') {
                dtoDatas[0].code = this.apartmentCode
              }
              if (this.apartmentType == '7') {
                if(this.rowRoomForm.idType == '7'){
                  dtoDatas[0].studentCode = this.rowRoomForm.idCardNo
                }else{
                  dtoDatas[0].studentCode = this.rowRoomForm.studentCode
                }
              }
              if (this.queryData.leaseType == '1') {
                dtoDatas[0].contractId = this.$route.params.id.substring(2, this.$route.params.id.length);
              } else if (this.queryData.leaseType == '0' || this.queryData.leaseType == '2') {
                dtoDatas[0].contractId = this.$route.query.validContractId;
              }
            }else{
              dtoDatas.unshift({
                userId: this.userId,
                gender: this.rowRoomForm.gender ? this.rowRoomForm.gender : '1',
                realName: this.rowRoomForm.realName,
                phoneNum: this.rowRoomForm.havePhone == '1' ? this.rowRoomForm.phoneNum : null,
                idType: this.rowRoomForm.haveCert == '1' ? this.rowRoomForm.idType : null,
                idCardNo: this.rowRoomForm.haveCert == '1' ? this.rowRoomForm.idCardNo : null,
                idCardPic1: this.rowRoomForm.idCardPic1.length > 0 ? this.rowRoomForm.idCardPic1[0].url : null,
                havePhone: this.rowRoomForm.havePhone,
                leaseId: this.$route.query.leaseId,
                // passWord: (this.rowRoomForm.havePhone == '1' || this.rowRoomForm.pwSearchFlag || this.rowRoomForm
                //     .haveCert == '0') ? '' : _this.AES
                //   .encrypt(this.rowRoomForm.passWord),
              })
              if (this.rowRoomForm.havePhone == '0' && !this.rowRoomForm.pwSearchFlag && this.rowRoomForm
                .haveCert == '1') {
                dtoDatas[0].passWord = _this.AES.encrypt(this.rowRoomForm.passWord)
              }
              if (this.apartmentCode && this.rowRoomForm.idType == '7') {
                dtoDatas[0].code = this.apartmentCode
              }
              if (this.apartmentType == '7') {
                if(this.rowRoomForm.idType == '7'){
                  dtoDatas[0].studentCode = this.rowRoomForm.idCardNo
                }else{
                  dtoDatas[0].studentCode = this.rowRoomForm.studentCode
                }
              }
              if (this.queryData.leaseType == '1') {
                dtoDatas[0].contractId = this.$route.params.id.substring(2, this.$route.params.id.length);
              } else if (this.queryData.leaseType == '0' || this.queryData.leaseType == '2') {
                dtoDatas[0].contractId = this.$route.query.validContractId;
              }
            }
            this.post('/tenant-service/leasecontract/updatePlanUser', dtoDatas, {
              isUseResponse: true,
              operatorFlage: true,
              operateName: '修改排房信息',
              isNotShowError: true
            }).then(data => {
              if (data.data.code == 0) {
                this.$message({
                  message: "修改成功",
                  type: "success"
                });
                this.getInitData();
                this.platoonRoomVisible = false;
              }
            }).catch(err => {
              if(err.data.code == 999226){
                _this.$alert(err.data.msg, '学号/职工号重复', {
                  confirmButtonText: '确定',
                  callback: action => {
                    _this.alertFlag = false
                  }
                });
              }else{
                this.$message({
                  showClose: true,
                  message: err.data.msg,
                  type: "warning"
                });
              }
            })
          }
        })
      },
      //文件上传数量超过限制
      onExceed() {
        this.$message({
          message: "文件上传数量超过限制，请删除后重新上传",
          type: "warning"
        });
      },
      //图片上传的图片删除
      onBeforeRemoveByUploadImg() {
        return (file, fileList) => {
          return new Promise((resolve, reject) => {
            let is = true;
            if (file.raw) {
              is = ["image/png", "image/jpeg"].indexOf(file.raw.type) != -1 && (file.raw.size / 1024 / 1024 < 30);
            }
            if (is) {
              this.$confirm("确认删除此图片吗？", "提示", {
                  confirmButtonText: "确定",
                  cancelButtonText: "取消",
                  type: "warning"
                })
                .then(() => {
                  // item.opt.remove(file, fileList);
                  resolve();
                })
                .catch(function () {
                  reject();
                });
            } else {
              resolve();
            }
          });
        };
      },
      //配合图片上传确认item
      onPreviewByUploadImg(file) {
        this.dialogImageUrl = file.url;
        this.dialogImgVisible = true;
      },
      /**
       * 上传文件之前的钩子
       */
      onBeforeUpload() {
        return file => {
          let is = ["image/png", "image/jpeg"].indexOf(file.type) != -1;
          let size = file.size / 1024 / 1024 > 30;
          if (!is) {
            // console.log("上传文件之前的钩子", item, file, is);
            this.$message.error("只能上传图片");
            return false;
          } else if (size) {
            this.$message.error("上传的图片不能超过30M");
            return false;
          } else {
            return true;
          }

        };
      },
      // 房客图片删除
      onHandleRemove(key, flag, index) {
        return (file, fileList) => {
          if (index == undefined) {
            if (flag == '1') {
              this.addRoommateForm[key] = fileList;
            } else if (flag == '2') {
              this.rowRoomForm[key] = fileList;
            }

          } else {
            this.cohabitantList[index][key] = fileList;
          }
          this.$forceUpdate();
        };
      },
      //房客表单图片单张上传
      onSuccessByUploadImgOnly(key, flag, index) {
        return (response, file, fileList) => {
          let keyName = "picPath";
          if (index === undefined) {
            if (flag == '1') {
              this.$set(this.addRoommateForm, key, [{
                url: response.data[keyName]
              }])
            } else if (flag == '2') {
              this.$set(this.rowRoomForm, key, [{
                url: response.data[keyName]
              }])
            }


          } else {
            this.$set(this.cohabitantList[index], key, [{
              url: response.data[keyName]
            }])
          }
          this.$forceUpdate();
        };
      },
      platoonRoomBtn() {
        let _this = this;
        _this.cohabitantList = [];
        let dto = {
          roomId: this.queryData.roomId,
        }
        if (this.queryData.leaseType == '1') {
          dto.shortRentId = this.$route.params.id.substring(2, this.$route.params.id.length);
        } else if (this.queryData.leaseType == '0' || this.queryData.leaseType == '2') {
          dto.contractId = this.$route.params.id;
        }
        _this.post(
          '/landlord-service/leasingCenter/searchLeasingCenterRoom',
          dto).then(data => {
          this.userId = data.userId;
          if (this.queryData.leaseType == '1') {
            this.getTenantData(data.userId, dto.shortRentId)
          } else {
            this.getTenantData(data.userId, this.$route.query.validContractId)
          }

        })
      },
      //获取可修改的房客信息
      getTenantData(userId, contractId) {
        let _this = this;
        _this.platoonRoomVisible = true;
        let dto = {
          userId: userId,
          contractId: contractId,
          rentalStyle: this.queryData.leaseType,
          leaseId: this.queryData.leaseId
        }
        _this.post(
          '/tenant-service/leaseroomuser/info?userId=' + userId + '&contractId=' + contractId + '&rentalStyle=' + this
          .queryData.leaseType,
          dto).then(data => {
          this.rowRoomForm.realName = data.realName;
          this.rowRoomForm.phoneNum = data.phoneNum;
          this.rowRoomForm.studentCode = data.studentCode;
          this.rowRoomForm.idType = data.idType + '';
          this.rowRoomForm.idCardNo = data.idCardNo;
          this.rowRoomForm.gender = data.gender.toString();
          if (data.phoneNum) {
            this.rowRoomForm.havePhone = '1';
          } else {
            this.rowRoomForm.havePhone = '0';
            this.rowRoomForm.passWord = 'Pw123456';
            this.rowRoomForm.passWordType = 'password';
            this.rowRoomForm.pwSearchFlag = true; //没有手机号码肯定会有密码，这个是查询回来的密码的标识
          }

          this.rowRoomForm.idCardPic1 = data.idCardPic1 ? [{
            url: data.idCardPic1
          }] : [];
          tenantidType = data.idType + '';
          if (data.idType == '1' && data.idCardNo) //已实名
          {
            this.rowRoomForm.certifiedFlage = true;
          } else {
            this.rowRoomForm.certifiedFlage = false;
          }
          if (data.idType && data.idCardNo) {
            this.rowRoomForm.haveCert = '1';
          } else {
            this.rowRoomForm.haveCert = '0';
          }
          this.getUserCohabitant(userId, contractId, cohabitData => {
            cohabitData.forEach(item => {
              let obj = {
                userId: item.userId,
                realName: item.realName,
                phoneNum: item.phoneNum,
                idType: item.idType + '',
                idCardNo: item.idCardNo,
                cardUrl: item.cardUrl,
                gender: item.gender,
                studentCode: item.studentCode,
              };
              if (item.phoneNum) {
                obj.havePhone = '1';
              } else {
                obj.havePhone = '0';
                obj.passWord = 'Pw123456';
                obj.passWordType = 'password';
                obj.pwSearchFlag = true;
              }
              obj.idCardPic1 = item.cardUrl ? [{
                url: item.cardUrl
              }] : [];
              if (item.idType == '1' && item.idCardNo) //已实名
              {
                obj.certifiedFlage = true;
              } else {
                obj.certifiedFlage = false;
              }
              if (item.idType && item.idCardNo) {
                obj.haveCert = '1';
              } else {
                obj.haveCert = '0';
              }
              _this.cohabitantList.push(obj)
            })
          })
          this.$forceUpdate();
          this.$nextTick(() => {
            _this.$refs["ruleForm"].clearValidate();
          })
        });
      },
      //获取房客同住人
      getUserCohabitant(userId, contractId, callback) {
        let _this = this;
        let dto = {
          userId: userId,
          roomId: this.queryData.roomId,
          contractId: contractId
        }
        _this.post(
          '/tenant-service/leaseroomuser/userCohabitant',
          dto).then(data => {
          if (callback) {
            callback(data)
          }
        });
      },
      getInitData() {
        let _this = this;
        let query = this.$route.query
        let dto = {
          id: _this.$route.query.validContractId,
          roomId: _this.queryData.roomId,
          userid: _this.queryData.userId,
          leaseId: this.$route.query.leaseId,
        }
        if(query.entrance == 2) {
          // dto.sign = 1
        }
        _this.post('/tenant-service/leaseroomuser/getTenantInfo', dto).then(data => {
          _this.roommateDataList = [];
          data.forEach(item => {
            if (item.contractType == 1) //签约者
            {

              if (item.state == 1) {
                if (item.gender == 1) {
                  this.signatoryData.headImg = require('../../assets/boy_blue.png');
                } else if (item.gender == 2) {
                  this.signatoryData.headImg = require('../../assets/girl_blue.png');
                }
              } else {
                if (item.gender == 1) {
                  this.signatoryData.headImg = require('../../assets/boy_grey.png');
                } else if (item.gender == 2) {
                  this.signatoryData.headImg = require('../../assets/girl_grey.png');
                }
              }
              this.signatoryData.realName = item.realName;
              this.signatoryData.phoneNum = item.phoneNum;
              this.signatoryData.idType = item.cardType ? this.cardStrToNum(item.cardType) : '';
              this.signatoryData.idCardNo = item.cardNo;
              this.signatoryData.gender = item.gender ? item.gender : '1';
              this.signatoryData.contractType = item.contractType;
              this.signatoryData.userId =  item.userId;
              this.signatoryData.studentCode =  item.studentCode;
              if (item.gender == 1) {
                this.signatoryData.genderStr = '男';
              } else if (item.gender == 2) {
                this.signatoryData.genderStr = '女';
              } else //未知也显示男
              {
                this.signatoryData.genderStr = '男';
              }
              this.signatoryData.state = item.state;
              if((this.queryData.leaseFrom == 2 || this.queryData.leaseFrom == 3) && this.signatoryData.state == 1) {
                if(this.getLandlordFlage() || this.getHousekeeperPlanAndQuitFlage()) {//房东或者有排房权限的房管员
                  this.isShowEditBtn = true;
                }else {
                  this.isShowEditBtn = false;
                }
              }else{
                this.isShowEditBtn = false;
              }
              this.isUploadPic = item.isUploadPic;
              this.isKey = item.isKey;
              this.apartmentCode = item.apartmentCode;
              this.apartmentType = item.apartmentType;
              if (item.phoneNum) {
                this.signatoryData.havePhone = '1';
              } else {
                this.signatoryData.havePhone = '0';
                this.signatoryData.passWord = 'Pw123456';
                this.signatoryData.passWordType = 'password';
              }
              tenantidType = item.cardType + '';
              if (this.userType != '8') {
                this.signatoryData.phoneNumStr = this.signatoryData.phoneNum ?
                  this.signatoryData.phoneNum.substring(0, 3) +
                  "****" +
                  this.signatoryData.phoneNum.substring(7, this.signatoryData.phoneNum.length) :
                  "";
                if (item.cardType == 'id_card') {
                  this.signatoryData.idCardNoStr = this.signatoryData.idCardNo ?
                    this.signatoryData.idCardNo.substring(0, 2) +
                    "******" +
                    this.signatoryData.idCardNo.substring(
                      this.signatoryData.idCardNo.length - 2,
                      this.signatoryData.idCardNo.length
                    ) :
                    "";
                } else {
                  this.signatoryData.idCardNoStr = this.signatoryData.idCardNo;
                }
              } else {
                this.signatoryData.phoneNumStr = this.signatoryData.phoneNum;
                this.signatoryData.idCardNoStr = this.signatoryData.idCardNo;
              }
              if (item.cardType == 'id_card' && item.cardNo) //已实名
              {
                this.signatoryData.certifiedFlage = true;
              } else {
                this.signatoryData.certifiedFlage = false;
              }
              if (item.cardType && item.cardNo) {
                this.signatoryData.haveCert = '1';
              } else {
                this.signatoryData.haveCert = '0';
              }
              if (this.queryData.leaseType == 1 && this.queryData.leaseState == 2 && (moment(new Date()).format(
                  'YYYY-MM-DD HH:mm:ss') < moment(this.queryData.startDate).format('YYYY-MM-DD HH:mm:ss'))) {
                this.signatoryData.stateStr = '待入住'
              } else {
                this.signatoryData.stateStr = ['已退房', '在租'][this.signatoryData.state];
              }

            } else if (item.contractType == 2) //同住人
            {
              let obj = {
                userId: item.userId,
                realName: item.realName,
                gender: item.gender ? item.gender : '1',
                phoneNum: item.phoneNum,
                idType: item.cardType ? this.cardStrToNum(item.cardType) : '',
                idCardNo: item.cardNo,
                state: item.state,
                headImg: require('../../assets/boy_grey.png'),
                contractType: item.contractType,
                studentCode: item.studentCode
              };
              if (item.state == 1) {
                if (item.gender == 1) {
                  obj.headImg = require('../../assets/boy_blue.png');
                } else if (item.gender == 2) {
                  obj.headImg = require('../../assets/girl_blue.png');
                }
              } else {
                if (item.gender == 1) {
                  obj.headImg = require('../../assets/boy_grey.png');
                } else if (item.gender == 2) {
                  obj.headImg = require('../../assets/girl_grey.png');
                }
              }
              if (item.gender == 1) {
                obj.genderStr = '男';
              } else if (item.gender == 2) {
                obj.genderStr = '女';
              } else //未知默认显示男
              {
                obj.genderStr = '男';
              }
              if (this.userType != '8') {
                obj.phoneNumStr = obj.phoneNum ?
                  obj.phoneNum.substring(0, 3) +
                  "****" +
                  obj.phoneNum.substring(7, obj.phoneNum.length) :
                  "";
                if (item.cardType == 'id_card') {
                  obj.idCardNoStr = obj.idCardNo ?
                    obj.idCardNo.substring(0, 2) +
                    "******" +
                    obj.idCardNo.substring(
                      obj.idCardNo.length - 2,
                      obj.idCardNo.length
                    ) :
                    "";
                } else {
                  obj.idCardNoStr = obj.idCardNo;
                }
              } else {
                obj.phoneNumStr = obj.phoneNum;
                obj.idCardNoStr = obj.idCardNo;
              }
              if (item.phoneNum) {
                obj.havePhone = '1';
              } else {
                obj.havePhone = '0';
                obj.passWord = 'Pw123456';
                obj.passWordType = 'password';
              }
              if (item.cardType == 'id_card' && item.cardNo) //已实名
              {
                obj.certifiedFlage = true;
              } else {
                obj.certifiedFlage = false;
              }
              if (item.cardType && item.cardNo) {
                obj.haveCert = '1';
              } else {
                obj.haveCert = '0';
              }
              if (this.queryData.leaseType == 1 && this.queryData.leaseState == 2 && moment(new Date()).format(
                  'YYYY-MM-DD HH:mm:ss') < (moment(this.queryData.startDate).format('YYYY-MM-DD HH:mm:ss'))) {
                obj.stateStr = '待入住';
              } else {
                obj.stateStr = ['已退房', '在租','已退房'][item.state];
              }
              _this.roommateDataList.push(obj)
            }
            let conhabitantsLiving;
             data.find(item => {
               if(item.contractType == 2 && item.state == 1) {
                 return conhabitantsLiving = true;
               }else {
                 return conhabitantsLiving = false;
               }
               
             })
            // 房东登录，针对排房并且开启开启退房模式2，且该房间存在住同住人的情况，显示删除按钮 
            if(_this.userType == '2' && conhabitantsLiving && _this.queryData.leaseFrom == 2 &&  _this.queryData.outState == 2) {
              _this.showDeleteTenantBtn = true;
            }else {
              _this.showDeleteTenantBtn = false;
            }
          })
        });
      },
    }
  };
</script>
<style lang="scss">
  .lease-tenant-detail {
    width: 100% !important;

    .top {
      position: absolute;
      right: 0px;
      top: 60px
    }

    .tenant-cont {
      display: flex;
      flex-wrap: wrap;
      width: 100% !important;
      justify-content: space-between;

      .cont-box-empty {
        width: 538px;
      }

      .cont-box {
        padding-left: 40px;
        padding-top: 28px;
        padding-bottom: 25px;
        width: 538px;
        background: #F7F7F7;
        border-radius: 12px;
        box-sizing: border-box;
        // margin-right: 30px;
        margin-bottom: 32px;
        box-sizing: border-box;
        display: flex;
        position: relative;

        // &:nth-child(3n) {
        //   margin-right: 0;
        // }

        .cont-left {
          text-align: center;

          .img {
            border-radius: 50%;
            width: 79px;
            height: 74px;
          }

          .txt {
            line-height: 30px;
            font-size: 14px;
            padding: 0;
            margin: 0;
            color: #666666;
          }
        }

        .cont-right {
          margin-left: 33px;
          margin-top: 8px;

          p {
            line-height: 25px;
            font-size: 14px;
            padding: 0;
            margin: 0;
          }

          .name {
            font-size: 18px;
            font-weight: 700;
            color: #333333;
          }

          .state {
            position: absolute;
            right: 40px;
            top: 40px;
            font-size: 15px;
            color: #888888;
          }

          .gender {
            padding-left: 10px;
            padding-right: 10px;
          }

          .delete {
            position: absolute;
            right: 40px;
            bottom: 41px;
            color: #30BAC1;
            font-size: 15px;
            cursor: pointer;
          }
        }
      }

    }

    .empty-text {
      margin-top: 200px;
      text-align: center;
      color: #909399;
    }

    .platoon-room-dialog {
      .el-dialog {
        margin-top: 10vh !important;
      }

      .dialog-box {
        height: 700px;
        overflow-y: auto;
      }

      .tip-top {
        font-size: 14px;
        color: #FF9933;
        padding-left: 53px;
      }

      .ipnut-box {
        display: flex;
        align-items: center;
      }

      .label-title {
        font-size: 15px;
        font-weight: 600;
      }

      .tip-right {
        padding: 0;
        margin: 0;
        padding-left: 5px;
        font-size: 14px;
        color: rgb(204, 204, 204);
        line-height: 18px;
      }

      .el-input {
        // width: 50% !important;
      }

      .el-form-item__content {
        width: 45%;
      }
    }
  }
</style>