<template>
  <div class="contract-detail">
    <div class="contract-box" v-for="(item,index) in dataList" :key="index">
      <div class="top">
        <div class="top-lt">
          <span>租约合同</span>
          <span>{{item.title}}</span>
        </div>
        <div class="top-rt">
          <span class="state-txt">{{item.stateStr}}</span>
        </div>
      </div>
      <div class="cont">
        <div class="list">
          <div class="list-lt">合同编号：</div>
          <div class="list-rt">{{item.id}}</div>
        </div>
        <div class="list">
          <div class="list-lt">签约主体人：</div>
          <div class="list-rt">{{item.name}}</div>
        </div>
        <div class="list">
          <div class="list-lt">电话：</div>
          <div class="list-rt">{{item.phoneNumStr}}</div>
        </div>
        <div class="list">
          <div class="list-lt">租期：</div>
          <div class="list-rt">{{item.tenancyTerm}}</div>
        </div>
        <div class="list">
          <div class="list-lt">合同起止日期：</div>
          <div class="list-rt">{{item.contractDate}}</div>
        </div>

        <div class="list">
          <div class="list-lt">签约房间信息：</div>
          <div class="list-rt">{{item.roomInfo}}</div>
        </div>
        <div class="list">
          <div class="list-lt">签约租金：</div>
          <div class="list-rt">{{item.rentalFee}}元/月</div>
        </div>
        <div class="list">
          <div class="list-lt">签约押金：</div>
          <div class="list-rt">{{item.deposit}}元</div>
        </div>
        <div class="list">
          <div class="list-lt">签约物业费：</div>
          <div class="list-rt">{{item.propertyFee}}元/月</div>
        </div>
        <div class="list">
          <div class="list-lt">签约时间：</div>
          <div class="list-rt">{{item.createdDate}}</div>
        </div>
        <div class="list">
          <div class="list-lt">签约状态：</div>
          <div class="list-rt">{{item.signingStatusStr}}</div>
        </div>
        <div class="list">
          <div class="list-lt">合同类型：</div>
          <div class="list-rt">{{item.contractTypeStr}} <span class="active" @click="lookBtn(item.downloadUrl)"
              v-show="item.contractType=='2'">查看</span><span class="active" v-show="item.contractType=='2'"
              @click="downloadBtn(item.id)">下载</span></div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  import axios from "axios";
  import Vue from "vue";
  export default {
    data() {
      let _this = this;
      return {
        dataList: []
      };
    },
    created() {
      this.userType = this.cache.getLS("userInfo")["roleType"];
    },
    activated() {
      console.log("contract-detail created!!");
      this.type = this.$route.query.type;
      this.optList = [];
      // this.roomId = this.$route.query.roomid || "";
      this.userType = this.cache.getLS("userInfo")["roleType"];
      this.dataList=[];
     this.getInitData();
    },
    methods: {
      lookBtn(downloadUrl) {
        if (downloadUrl) {
          var tempwindow = window.open('_blank');
          tempwindow.location = downloadUrl;
        }
      },
      downloadBtn(id) {
        axios({
            method: "get",
            url: Vue.config.BASE_URL + "/tenant-service/leasecontract/downloadContractFile/" + id,
            responseType: "blob"
          })
          .then(response => {
            var elink = document.createElement("a");
            elink.download = "合同.pdf";
            elink.style.display = "none";
            var blob = new Blob([response.data]);
            elink.href = URL.createObjectURL(blob);
            document.body.appendChild(elink);
            elink.click();
            document.body.removeChild(elink);
          })
          .catch(error => {
            window.open(data["downloadUrl"]);
          });
      },
      getInitData() {
        let _this = this;
        console.log('this.$route.query.id', this.$route.params.id);
        let dto = {
          contractId: this.$route.params.id,
          pageNum: 1,
          pageSize: 9999,
          roomId: this.$route.query.roomId,
          isRent:1,
          leaseId: this.$route.query.leaseId,
        }
        _this.post("tenant-service/v1/lease-info/query/get-tenant-contract-info?leaseId="+this.$route.query.leaseId).then(res => {
          res.forEach(item => {
            item.signingStatusStr = [
              "待支付",
              "签约成功",
              "签约失败",
              "合同到期",
              "已解约",
              "强制解约"
            ][item.state];
            //合同状态
            // 待生效（1、待支付，2、续签的合同还没到时间）
            // 已失效（签约失败）
            // 履约中（签约成功、在租、退房中）
            // 已过期（合同到期）
            // 已解约（已退房，已强制解约）
            item.stateStr = [
                "待生效",
                "履约中",
                "签约失败",
                "已过期",
                "已解约",
                "强制解约"
              ][item.contractState];
            for (var key in item) {
              if (key == "payState") {
                item[key] = item.payState == 3 ? "已缴费" : "未缴费";
              }
              // if (key == "gender" && item[key]) {
              //   item[key] = ["男", "女"][item[key] - 1];
              // }
              if (key == "apartmentName" && item[key]) {
                item[key] = `${item.city}${item[key]}房源${item.buildNo}${item.roomNo}房`;
              }
            }
            item.contractTypeStr = item.isPreview == 1 ? "线下纸质合同" : "CA电子合同";
            if (this.cache.getLS("userInfo")["roleType"] != "8") {
              item.phoneNumStr = item.phone ?
                item.phone.substring(0, 3) +
                "****" +
                item.phone.substring(7, item.phone.length) :
                "";
            }

          });

          _this.dataList = res;
        });
      },

    }
  };
</script>
<style lang="scss">
  .contract-detail {
    .contract-box {
      border: 1px solid rgba(228, 228, 228, 1);
      border-radius: 7px;
      // width: 1200px;
      margin-bottom: 20px;

      .top {
        display: flex;
        height: 40px;
        font-size: 14px;
        line-height: 1;
        // font-weight: 700;
        background: rgba(0, 153, 153, 0.0588235294117647);
        color: rgba(51, 51, 51, 0.8);
        justify-content: space-between;
        align-items: center;
        padding-left: 24px;
        padding-right: 38px;

        .state-txt {
          color: #888888;
        }
      }

      .cont {
        width: 100%;
        display: flex;
        padding-right: 38px;
        margin-bottom: 20px;
        box-sizing: border-box;
        flex-wrap: wrap;

        .list {
          width: 33.33%;
          display: flex;
          margin-top: 20px;

          .list-lt {
            font-size: 14px;
            color: #666666;
            width: 40%;
            text-align: right;
          }

          .list-rt {
            font-size: 14px;
            color: #666666;
            width: 65%;
            text-align: left;
            word-wrap: break-word;
            // font-weight: 700;

            .active {
              margin-left: 10px;
              color: #30BAC1;
              cursor: pointer;
            }
          }
        }
      }
    }
  }
</style>