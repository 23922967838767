<template>
  <div class="lease-detail">
    <div class="top">
      <el-button type="primary" v-if="checkOutBtnShow" @click="checkOut">同意退房</el-button>
      <el-button type="primary" v-if="terminationBtnShow" @click="goTermination">强制解约</el-button>
    </div>
    <div class="title">房间信息</div>
    <div class="cont-info bottom-line">
      <div class="info-box" v-for="item in roomData" :key="item.key">
        <div class="lt">{{item.label}}：</div>
        <div class="rt">{{roomInfoData[item.key]}}</div>
      </div>
    </div>
    <div class="title">租约信息</div>
    <div class="cont-info bottom-line">
      <div class="info-box" v-for="item in leaseData" :key="item.key">
        <div class="lt">{{item.label}}：</div>
        <div class="rt">{{rentInfoData[item.key]?rentInfoData[item.key]:'--'}}</div>
      </div>
    </div>
    <div v-if="rentInfoData.state=='7' || rentInfoData.state=='6' || rentInfoData.state=='5'|| rentInfoData.state=='3'">
      <div class="title">退房信息</div>
      <div class="cont-info">
        <div class="info-box" v-for="item in checkOutData" :key="item.key">
          <div class="lt">{{item.label}}：</div>
          <div class="rt">{{extraInfoData[item.key]?extraInfoData[item.key]:'--'}}</div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  export default {
    data() {
      let _this = this;
      return {
        type: "",
        contractId: "",
        userType: "",
        isDisabled: true,
        roomInfoData: {},
        rentInfoData: {},
        extraInfoData: {},
        checkOutBtnShow: false,
        terminationBtnShow: false,
        // 学校房源-长租房间信息
        schoolRoomDataLongTemplate: 
          [{
              label: "房间信息",
              key: "roomName",
            },
            {
              label: "房型",
              key: "roomType",
            },
            {
              label: "租赁模式",
              key: "rentModelStr",
            }
          ],
        // 学校房源-排房长租租约信息
        schoolLeaseDataLongPlanTemplate: 
          [{
              label: "签约人",
              key: "realName",
            },
            {
              label: "联系电话",
              key: "phoneNum",
            },
            {
              label: "签约证件类型",
              key: "cardTypeStr",
            },
            {
              label: "签约证件号码",
              key: "idcardnoStr",
            },
            {
              label: "排房时间",
              key: "createDate",
            },
            {
              label: "租期",
              key: "intervalDate",
            },
            {
              label: "排房起止日期",
              key: "leaseDate",
            },
            {
              label: "租约类型",
              key: "reservationTypeStr",
            },
            {
              label: "入住人数",
              key: "liveNum",
            },
            {
              label: "租约状态",
              key: "leaseStateStr",
            },
          ],

        roomDataLongTemplate: //长租房间信息
          [{
              label: "房间信息",
              key: "roomName",
            },
            {
              label: "房型",
              key: "roomType",
            },
            {
              label: "租赁模式",
              key: "rentModelStr",
            },
            {
              label: "租赁合同类型",
              key: "isPrevieweStr",
            },
            {
              label: "租金",
              key: "rentalFeeStr",
            },
            {
              label: "押金",
              key: "depositStr",
            },
            {
              label: "物业费",
              key: "propertyFeeStr",
            },
            {
              label: "电费",
              key: "electricityFeesPriceStr",
            },
            {
              label: "冷水费",
              key: "coldWaterPriceStr",
            },
            {
              label: "热水费",
              key: "hotWaterPriceStr",
            },
          ],
        roomDataShortTemplate: //短租房间信息
          [{
              label: "房间信息",
              key: "roomName",
            },
            {
              label: "房型",
              key: "roomType",
            },
            {
              label: "租赁模式",
              key: "rentModelStr",
            },
            {
              label: "租金",
              key: "rentalFeeStr",
            },
            {
              label: "押金",
              key: "depositStr",
            },
          ],
        roomDataClockTemplate: //钟点房房间信息
          [{
              label: "房间信息",
              key: "roomName",
            },
            {
              label: "房型",
              key: "roomType",
            },
            {
              label: "租赁模式",
              key: "rentModelStr",
            },
            {
              label: "租金",
              key: "rentalFeeStr",
            },
            {
              label: "押金",
              key: "depositStr",
            },
          ],
        leaseDataLongCustomTemplate: //自订长租租约信息
          [{
              label: "签约人",
              key: "realName",
            },
            {
              label: "联系电话",
              key: "phoneNum",
            },
            {
              label: "签约证件类型",
              key: "cardTypeStr",
            },
            {
              label: "签约证件号码",
              key: "idcardnoStr",
            },
            {
              label: "签约时间",
              key: "createDate",
            },
            {
              label: "租期",
              key: "intervalDate",
            },
            {
              label: "租住起止日期",
              key: "leaseDate",
            },
            {
              label: "租约类型",
              key: "reservationTypeStr",
            },
            {
              label: "签约租金",
              key: "rentFeeStr",
            },
            {
              label: "签约押金",
              key: "depositAmountStr",
            },
            {
              label: "签约物业费",
              key: "qyPropertyFeeStr",
            },
            {
              label: "入住人数",
              key: "liveNum",
            },
            {
              label: "租约状态",
              key: "leaseStateStr",
            },
          ],
        leaseDataLongPlanTemplate: //排房长租租约信息
          [{
              label: "签约人",
              key: "realName",
            },
            {
              label: "联系电话",
              key: "phoneNum",
            },
            {
              label: "签约证件类型",
              key: "cardTypeStr",
            },
            {
              label: "签约证件号码",
              key: "idcardnoStr",
            },
            {
              label: "排房时间",
              key: "createDate",
            },
            {
              label: "租期",
              key: "intervalDate",
            },
            {
              label: "排房起止日期",
              key: "leaseDate",
            },
            {
              label: "租约类型",
              key: "reservationTypeStr",
            },
            {
              label: "签约租金",
              key: "rentFeeStr",
            },
            {
              label: "签约押金",
              key: "depositAmountStr",
            },
            {
              label: "签约物业费",
              key: "qyPropertyFeeStr",
            },
            {
              label: "入住人数",
              key: "liveNum",
            },
            {
              label: "租约状态",
              key: "leaseStateStr",
            },
          ],
        leaseDataShortCustomTemplate: //自订短租租约信息
          [{
              label: "签约人",
              key: "realName",
            },
            {
              label: "联系电话",
              key: "phoneNum",
            },
            {
              label: "签约证件类型",
              key: "cardTypeStr",
            },
            {
              label: "签约证件号码",
              key: "idcardnoStr",
            },
            {
              label: "签约时间",
              key: "createDate",
            },
            {
              label: "租期",
              key: "intervalDate",
            },
            {
              label: "租住起止日期",
              key: "leaseDate",
            },
            {
              label: "租约类型",
              key: "reservationTypeStr",
            },
            {
              label: "签约租金",
              key: "rentFeeStr",
            },
            {
              label: "签约押金",
              key: "depositAmountStr",
            },
            {
              label: "入住人数",
              key: "liveNum",
            },
            {
              label: "租约状态",
              key: "leaseStateStr",
            },
          ],
        leaseDataShortPlanTemplate: //排房短租租约信息
          [{
              label: "签约人",
              key: "realName",
            },
            {
              label: "联系电话",
              key: "phoneNum",
            },
            {
              label: "签约证件类型",
              key: "cardTypeStr",
            },
            {
              label: "签约证件号码",
              key: "idcardnoStr",
            },
            {
              label: "排房时间",
              key: "createDate",
            },
            {
              label: "租期",
              key: "intervalDate",
            },
            {
              label: "排房起止日期",
              key: "leaseDate",
            },
            {
              label: "租约类型",
              key: "reservationTypeStr",
            },
            {
              label: "签约租金",
              key: "rentFeeStr",
            },
            {
              label: "签约押金",
              key: "depositAmountStr",
            },
            {
              label: "入住人数",
              key: "liveNum",
            },
            {
              label: "租约状态",
              key: "leaseStateStr",
            },
          ],
        leaseDataClockCustomTemplate: //钟点房租约信息
          [{
              label: "签约人",
              key: "realName",
            },
            {
              label: "联系电话",
              key: "phoneNum",
            },
            {
              label: "签约证件类型",
              key: "cardTypeStr",
            },
            {
              label: "签约证件号码",
              key: "idcardnoStr",
            },
            {
              label: "签约时间",
              key: "createDate",
            },
            {
              label: "租期",
              key: "intervalDate",
            },
            {
              label: "租住时间",
              key: "leaseDate",
            },
            {
              label: "租约类型",
              key: "reservationTypeStr",
            },
            {
              label: "签约租金",
              key: "rentFeeStr",
            },
            {
              label: "签约押金",
              key: "depositAmountStr",
            },
            {
              label: "入住人数",
              key: "liveNum",
            },
            {
              label: "租约状态",
              key: "leaseStateStr",
            },
          ],
        checkOutDataLongCustomTemplate: //自订长租退房信息
          [{
              label: "提交申请退房时间",
              key: "checkoutDate",
            },
            {
              label: "退房日期",
              key: "applyCheckoutDate",
            },
            {
              label: "退款单号",
              key: "refundNo",
            },
            {
              label: "租金应退",
              key: "checkoutRentalFeeStr",
            },
            {
              label: "押金应退",
              key: "checkoutDepositFeeStr",
            },
            {
              label: "物业费应退",
              key: "checkoutPropertyFeeStr",
            },
            {
              label: "扣款金额",
              key: "deductionFeeStr",
            },
            {
              label: "退款总金额",
              key: "checkoutSumStr",
            },
            {
              label: "房东审核退房时间",
              key: "agreeQuitTime",
            },
            {
              label: "房东是否有扣款项",
              key: "deductionDescStr",
            },
            {
              label: "退款状态",
              key: "operationStateStr",
            },
            {
              label: "退款到账时间",
              key: "successTime",
            },
          ],
        checkOutDataShortCustomTemplate: //自订短租退房信息
          [{
              label: "提交申请退房时间",
              key: "checkoutDate",
            },
            {
              label: "退房日期",
              key: "applyCheckoutDate",
            },
            {
              label: "退款单号",
              key: "refundNo",
            },
            {
              label: "租金应退",
              key: "checkoutRentalFeeStr",
            },
            {
              label: "押金应退",
              key: "checkoutDepositFeeStr",
            },
            {
              label: "扣款金额",
              key: "deductionFeeStr",
            },
            {
              label: "退款总金额",
              key: "checkoutSumStr",
            },
            {
              label: "房东审核退房时间",
              key: "agreeQuitTime",
            },
            {
              label: "房东是否有扣款项",
              key: "deductionDescStr",
            },
            {
              label: "退款状态",
              key: "operationStateStr",
            },
            {
              label: "退款到账时间",
              key: "successTime",
            },
          ],
        checkOutDataClockCustomTemplate: //钟点房退房信息
          [{
              label: "提交申请退房时间",
              key: "checkoutDate",
            },
            {
              label: "退房时间",
              key: "applyCheckoutDate",
            },
            {
              label: "退款单号",
              key: "refundNo",
            },
            {
              label: "租金应退",
              key: "checkoutRentalFeeStr",
            },
            {
              label: "押金应退",
              key: "checkoutDepositFeeStr",
            },
            {
              label: "扣款金额",
              key: "deductionFeeStr",
            },
            {
              label: "退款总金额",
              key: "checkoutSumStr",
            },
            {
              label: "房东审核退房时间",
              key: "agreeQuitTime",
            },
            {
              label: "房东是否有扣款项",
              key: "deductionDescStr",
            },
            {
              label: "退款状态",
              key: "operationStateStr",
            },
            {
              label: "退款到账时间",
              key: "successTime",
            },
          ],
        checkOutDataPlanTemplate: //排房退房信息--长租短租
          [{
              label: "提交申请退房时间",
              key: "checkoutDate",
            },
            {
              label: "退房日期",
              key: "applyCheckoutDate",
            },
            {
              label: "房东审核退房时间",
              key: "agreeQuitTime",
            },
          ],
        forceDataCustomTemplate: //强制解约信息--自订排房
          [{
              label: "强制退房时间",
              key: "createdDate",
            },
            {
              label: "强制退房类型",
              key: "rescindType",
            },
            {
              label: "强制退房说明",
              key: "remark",
            },
            {
              label: "操作人",
              key: "createdName",
            },
            // {
            //   label: "强制退房短信推送",
            //   key: "yjyt",
            // },
          ],

        roomData: [],
        leaseData: [],
        checkOutData: [],
        formData: {},
      };
    },
    created() {
      // this.roomId = this.$route.query.roomid || "";
      this.formData = this.$route.query;
      this.userType = this.cache.getLS("userInfo")["roleType"];
      this.roomInfoData = {};
      this.rentInfoData = {};
      this.extraInfoData = {};
      // this.roomData = [];
      // this.leaseData = [];
      // this.checkOutData = [];
      // this.formData = {};
      this.getData();
    },
    activated() {
      console.log("lease-detail created!!");
      this.formData = this.$route.query;
      this.userType = this.cache.getLS("userInfo")["roleType"];
      this.roomInfoData = {};
      this.rentInfoData = {};
      this.extraInfoData = {};
      // this.roomData = [];
      // this.leaseData = [];
      // this.checkOutData = [];
      // this.formData = {};
      this.getData();
    },
    methods: {
      formatDate () {
          var date = new Date();
          var YY = date.getFullYear() + '-';
          var MM = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1) + '-';
          var DD = date.getDate() < 10 ? '0' + date.getDate() : date.getDate();
          var hh = (date.getHours() < 10 ? '0' + date.getHours() : date.getHours()) + ':';
          var mm = (date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes()) + ':';
          var ss = date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds();
          return YY + MM + DD + ' ' + hh + mm + ss;
        },

      getData() {
        let _this = this;
        //长租不显示时分秒
        //短租显示14:00
        console.log('this.formData', this.formData)
        let typeStr1 = ''
        if (_this.formData.leaseFrom == 2){//排房
          typeStr1 = '排房/';
          if (_this.formData.leaseType == 2 || _this.formData.leaseType == 0) {
            //长租排房租约信息
            this.leaseData = this.leaseDataLongPlanTemplate;
            //长租排房退房信息
            this.checkOutData = this.checkOutDataPlanTemplate;
          } else if (_this.formData.leaseType == 1) {
            //短租排房租约信息
            this.leaseData = this.leaseDataShortPlanTemplate;

            //短租排房退房信息
            this.checkOutData = this.checkOutDataPlanTemplate;
          }

        } else if(_this.formData.leaseFrom == 1){//自订
          typeStr1 = '自订/';
          if (_this.formData.leaseType == 2 || _this.formData.leaseType == 0) {
            //长租自订租约信息
            this.leaseData = this.leaseDataLongCustomTemplate;
            //长租自订退房信息
            this.checkOutData = this.checkOutDataLongCustomTemplate;
          } else if (_this.formData.leaseType == 1) {
            //短租自订租约信息
            this.leaseData = this.leaseDataShortCustomTemplate;
            //短租自订退房信息
            this.checkOutData = this.checkOutDataShortCustomTemplate;
          } else if (_this.formData.leaseType == 3) {
            //钟点房租约信息
            this.leaseData = this.leaseDataClockCustomTemplate;
            //钟点房退房信息
            this.checkOutData = this.checkOutDataClockCustomTemplate;
          }
        } else if(_this.formData.leaseFrom == 3) { //PMS排房
          typeStr1 = ''
          this.leaseData = this.leaseDataShortPlanTemplate;
          this.checkOutData = this.checkOutDataPlanTemplate;
        }
        //房间信息
        if (_this.formData.leaseType == 2 || _this.formData.leaseType == 0) //长租房间信息
        {
          this.roomData = this.roomDataLongTemplate;
        } else if (_this.formData.leaseType == 1) //短租房间信息
        {
          this.roomData = this.roomDataShortTemplate;
        } else if (_this.formData.leaseType == 3) //钟点房房间信息
        {
          this.roomData = this.roomDataClockTemplate;
        } else if (_this.formData.leaseType == 4) {
          this.roomData = this.roomDataShortTemplate;
        }
        // let aa = this.$route.query
        let dto = {
           id: _this.$route.params.id,
           leaseId: _this.formData.leaseId,
           leaseType: _this.formData.leaseType,
        };
        if(this.$route.query.entrance == 2) {//从房客列表进入查看租约，需要多传sign字段，从租约列表进入不传sign字段
          // dto.sign = 1;
        }
        let roleIds = this.cache.getLS("userInfo").roleIds;
        this.post("tenant-service/rent/getRentInfoDetail", dto).then(
          res => {
            // 学校类型的房源，隐藏房间信息中的租金、押金、物业费、电费、冷水费、热水费；隐藏租约信息中的签约租金、签约押金、签约物业费
            if(res.roomInfo.apartmentType == 7) {
              this.roomData = this.schoolRoomDataLongTemplate
              this.leaseData = this.schoolLeaseDataLongPlanTemplate;
            }
            if (res.rentInfo.state == '5' && res.checkoutInfo && res.checkoutInfo.checkoutState == '1') {
             if(this.getLandlordFlage() || this.getHousekeeperPlanAndQuitFlage()) {//房东 或 有排房操作权限的房管员
               this.checkOutBtnShow = true;
             }else {
               this.checkOutBtnShow = false;
             }
            } else {
              this.checkOutBtnShow = false;
            }
            if (res.rentInfo.state == '4' || res.rentInfo.state == '5' || res.rentInfo.state == '2') {
                  if(roleIds.indexOf('1') != -1 || roleIds.indexOf('2') != -1) {//超级管理员或者房东
                    let date = this.formatDate()
                    if(res.rentInfo.state == '2') {
                        this.terminationBtnShow = true;
                    } else if(res.checkoutInfo){
                      if(res.checkoutInfo.refundNo){
                        this.terminationBtnShow = false;
                      }else if(res.checkoutInfo.applyCheckoutDate < date && _this.formData.leaseType == 3){
                          if(res.checkoutInfo.agreeQuitTime){
                            this.terminationBtnShow = false;
                          }else{
                            this.terminationBtnShow = true;
                          }
                      }else {
                        if(_this.formData.leaseFrom == 2){
                          this.terminationBtnShow = false;
                        }else{
                          this.terminationBtnShow = true;
                        }
                      }
                    } else{
                      this.terminationBtnShow = true;
                    }
                  }else if(this.getHousekeeperPlanAndQuitFlage()) {//有排房操作权限的房管员
                    let date = this.formatDate()
                    if(res.rentInfo.state == '2') {
                        this.terminationBtnShow = true;
                    } else if(res.checkoutInfo){
                      if(res.checkoutInfo.refundNo){
                        this.terminationBtnShow = false;
                      }else if(res.checkoutInfo.applyCheckoutDate < date && _this.formData.leaseType == 3){
                          if(res.checkoutInfo.agreeQuitTime){
                            this.terminationBtnShow = false;
                          }else{
                            this.terminationBtnShow = true;
                          }
                      }else {
                        if(_this.formData.leaseFrom == 2){
                          this.terminationBtnShow = false;
                        }else{
                          this.terminationBtnShow = true;
                        }
                      }
                    }else{
                      this.terminationBtnShow = true;
                    }
                  }else {
                    this.terminationBtnShow = false;
                  }
                  
            } else {
              this.terminationBtnShow = false;
            }
            // 强制解约
            if (res.rentInfo.state == '7') {
              this.checkOutData = this.forceDataCustomTemplate;
            }
            res.roomInfo.rentModelStr = ['长租', '短租','','钟点房','短租'][res.roomInfo.rentModel];
            if(res.roomInfo.rentalFee) {
              if(res.roomInfo.rentModel == 1 || res.roomInfo.rentModel == 4) {
                res.roomInfo.rentalFeeStr = res.roomInfo.rentalFee + '元/天'
              } else if (res.roomInfo.rentModel == 3) {
                res.roomInfo.rentalFeeStr = res.roomInfo.rentalFee + '元/小时'
              } else {
                res.roomInfo.rentalFeeStr = res.roomInfo.rentalFee + '元/月'
              }
            } else {
              res.roomInfo.rentalFeeStr = '--'
            }
            res.roomInfo.depositStr = res.roomInfo.deposit ? (res.roomInfo.deposit + '元') : '--';
            res.roomInfo.propertyFeeStr = res.roomInfo.propertyFee ? (res.roomInfo.propertyFee + '元/月') : '--';

            res.rentInfo.qyPropertyFeeStr = res.rentInfo.propertyFee ? (res.rentInfo.propertyFee + '元/月') : '--';
            res.rentInfo.depositAmountStr = res.rentInfo.depositAmount ? (res.rentInfo.depositAmount + '元') : '--';
            if(res.rentInfo.rentFee) {
              if(res.roomInfo.rentModel == 1 || res.roomInfo.rentModel == 4) {
                res.rentInfo.rentFeeStr = res.rentInfo.rentFee + '元/天'
              } else if (res.roomInfo.rentModel == 3) {
                res.rentInfo.rentFeeStr = res.rentInfo.rentFee + '元/小时'
              } else {
                res.rentInfo.rentFeeStr = res.rentInfo.rentFee + '元/月'
              }
            } else {
              res.rentInfo.rentFeeStr = '--'
            }
            res.rentInfo.liveNum = res.rentInfo.liveNum == 0 ? res.rentInfo.liveNum + '' : res.rentInfo.liveNum;
            let chargingModel = res.roomInfo.chargingModel;
            // 收费模式 1：阶梯收费 2：固定价格   3：固定金额  4：自理
            if (chargingModel && chargingModel.length > 0) {
              //电费
              if (chargingModel[0]) {
                if (chargingModel[0]['model'] == 2) {
                  res.roomInfo.electricityFeesPriceStr = chargingModel[0]['unit_price'] + '元/度';
                } else if (chargingModel[0]['model'] == 4) {
                  res.roomInfo.electricityFeesPriceStr = '自理';
                }
              }
              //冷水费
              if (chargingModel[1]) {
                if (chargingModel[1]['model'] == 2) {
                  res.roomInfo.coldWaterPriceStr = chargingModel[1]['unit_price'] + '元/吨';
                } else if (chargingModel[1]['model'] == 3) {
                  res.roomInfo.coldWaterPriceStr = chargingModel[1]['unit_price'] + '元/月';
                } else if (chargingModel[1]['model'] == 4) {
                  res.roomInfo.coldWaterPriceStr = '自理';
                }
              }
              //电费
              if (chargingModel[2]) {
                if (chargingModel[2]['model'] == 2) {
                  res.roomInfo.hotWaterPriceStr = chargingModel[2]['unit_price'] + '元/吨';
                } else if (chargingModel[2]['model'] == 3) {
                  res.roomInfo.hotWaterPriceStr = chargingModel[2]['unit_price'] + '元/月';
                } else if (chargingModel[2]['model'] == 4) {
                  res.roomInfo.hotWaterPriceStr = '自理';
                }
              }

            }

            res.rentInfo.cardTypeStr = res.rentInfo.cardType ? this.certTypeArr[this.cardStrToNum(res.rentInfo
              .cardType)] : '--';
            
            res.rentInfo.leaseStateStr = this.leaseStatusStr[res.rentInfo.state];
            this.cache.set('leaseState',res.rentInfo.state)
            if (res.rentInfo.cardType == 'id_card' && this.userType != '8') {
              res.rentInfo.idcardnoStr = res.rentInfo.idcardno ?
                res.rentInfo.idcardno.substring(0, 2) +
                "******" +
                res.rentInfo.idcardno.substring(
                  res.rentInfo.idcardno.length - 2,
                  res.rentInfo.idcardno.length
                ) :
                "--";
            } else {
              res.rentInfo.idcardnoStr = res.rentInfo.idcardno;
            }
            if (this.userType != "8") {
              res.rentInfo.phoneNum = res.rentInfo.phoneNum ?
                res.rentInfo.phoneNum.substring(0, 3) +
                "****" +
                res.rentInfo.phoneNum.substring(7, res.rentInfo.phoneNum.length) :
                "--";
            }
            res.rentInfo.reservationTypeStr = typeStr1 + ['长租', '短租','长租','钟点房','PMS排房'][_this.formData.leaseType];
            if (_this.formData.leaseType == 1 || _this.formData.leaseType == 4) {
              res.rentInfo.leaseDate = moment(res.rentInfo.startDate).format("YYYY-MM-DD HH:mm") + '~' + moment(res
                .rentInfo.endDate).format("YYYY-MM-DD HH:mm")
              res.rentInfo.intervalDate = Math.abs(moment(res.rentInfo.startDate).diff(res.rentInfo.endDate, 'day')) +
                '天';

            } else if (_this.formData.leaseType == 3) {
              res.rentInfo.leaseDate = moment(res.rentInfo.startDate).format("YYYY-MM-DD HH:mm") + '~' + moment(res
                .rentInfo.endDate).format("HH:mm")
              res.rentInfo.intervalDate = Math.abs(moment(res.rentInfo.startDate).diff(res.rentInfo.endDate, 'Hour')) +
                '小时';
            } else {
              res.rentInfo.leaseDate = moment(res.rentInfo.startDate).format("YYYY-MM-DD") + '~' + moment(res.rentInfo
                .endDate).format("YYYY-MM-DD")
              res.rentInfo.intervalDate = this.getIntervalDate(res.rentInfo.startDate, res.rentInfo.endDate);
            }
            res.roomInfo.isPrevieweStr = ['', '线下纸质合同', 'CA电子合同'][res.rentInfo.isPreviewe];

            this.rentInfoData = res.rentInfo;
            this.roomInfoData = res.roomInfo;
            if (res.rentInfo.state == 7) //强制解约
            {
              this.extraInfoData.createdDate = res.rentInfo.createdDate;
              this.extraInfoData.rescindType = res.rentInfo.rescindType;
              this.extraInfoData.remark = res.rentInfo.remark;
              this.extraInfoData.createdName = res.rentInfo.createdName;
            } else if (res.rentInfo.state == 6 || res.rentInfo.state == 5 || res.rentInfo.state == 3) //退房信息
            {
              // 退款状态  0:退款中，1:退款中，2:退款成功，3:退款异常， 4:退款取消，5:退款失败  6：待确认
              res.checkoutInfo.operationStateStr = ['退款中', '退款中', '退款成功', '退款异常', '退款取消', '退款失败','待确认'][res.checkoutInfo
                .refundState
              ];
              if (parseFloat(res.checkoutInfo.deductionFee) >= 0) {
                res.checkoutInfo.deductionDescStr = '是';
              } else {
                res.checkoutInfo.deductionDescStr = '否';
              }
              res.checkoutInfo.checkoutRentalFeeStr = res.checkoutInfo.checkoutRentalFee ? (res.checkoutInfo
                .checkoutRentalFee + '元') : '';
              res.checkoutInfo.checkoutDepositFeeStr = res.checkoutInfo.checkoutDepositFee ? (res.checkoutInfo
                .checkoutDepositFee + '元') : '';
              res.checkoutInfo.checkoutPropertyFeeStr = res.checkoutInfo.checkoutPropertyFee ? (res.checkoutInfo
                .checkoutPropertyFee + '元') : '';
              res.checkoutInfo.deductionFeeStr = res.checkoutInfo.deductionFee ? (res.checkoutInfo.deductionFee +
                '元') : '';
              res.checkoutInfo.checkoutSumStr = res.checkoutInfo.checkoutSum ? (res.checkoutInfo.checkoutSum + '元') :
                '';
              // res.checkoutInfo.applyCheckoutDate=moment(res.checkoutInfo.applyCheckoutDate).format('YYYY-MM-DD');
              this.extraInfoData = res.checkoutInfo;
            }
          }
        );
      },
      getIntervalDate(leaseStartDate, leaseEndDate) {
        // if (moment(leaseStartDate).format('YYYY-MM-DD') == moment(leaseStartDate).startOf('month').format(
        //     'YYYY-MM-DD')) {
        //   return `${ Math.abs(moment(leaseEndDate).diff(leaseStartDate, 'month') + 1) + '个月'}`
        // } else {
        //   return `${ Math.abs(moment(leaseEndDate).diff(leaseStartDate, 'month')) + '个月'}`
        // }
        let date1 = leaseEndDate;
        let date2 = leaseStartDate;
        if (moment(leaseStartDate).format('YYYY-MM-DD').slice(8, 10) == '01') { //起始时间从1号开始 多加一个月
          return `${(moment(date1).years() - moment(date2).years()) * 12 + moment(date1).months() - moment(date2).months() + 1 + '个月'}`
        } else {
          return `${(moment(date1).years() - moment(date2).years()) * 12 + moment(date1).months() - moment(date2).months() + '个月'}`
        }
      },
      /**
       * 强制解约
       */
      goTermination() {
        let id = this.$route.params.id;
        if (this.formData.leaseType == 1) { //短租
          // this.$router.push("/main/tenant/shortTermination/show?rentId=" + id.substring(2, id.length));
          this.$router.push("/main/tenant/shortTermination/show?rentId=" + id + "&leaseId=" + this.formData.leaseId);
        } else {
          this.$router.push("/main/tenant/rowHousesTermination/show?contractId=" + id + "&leaseId=" + this.formData.leaseId);
        }
      },
      /**
       * 同意退房
       */
      checkOut(row) {
        let _this = this;
        // row.rentalStyle = row.rentalStyle == "长租" ? "0" : "1";
        // _this.extraInfoData.checkoutSum =
        //   _this.extraInfoData.checkoutSum == "" || _this.extraInfoData.checkoutSum == undefined ?
        //   "0.00" :
        //   _this.extraInfoData.checkoutSum;
        // _this.$router.push(
        //   "/main/CheckOut/AddDetail/edit/0" +
        //   "?realname=" +
        //   row.realname +
        //   "&address=" +
        //   row.address +
        //   "&quitDate=" +
        //   row.quitDate +
        //   "&checkoutSum=" +
        //   _this.extraInfoData.checkoutSum +
        //   "&rentalStyle=" +
        //   row.rentalStyle +
        //   "&roomId=" +
        //   row.roomId +
        //   "&shortRentId=" +
        //   row.shortRentId
        // );
        let id = _this.$route.params.id;
        if (this.formData.leaseType == 1) { //短租
          id = id.substring(2, id.length);
        }
        _this.$router.push({
          path: "/main/CheckOut/AddDetail/edit/0",
          query: {
            realname: _this.rentInfoData.realName,
            address: _this.roomInfoData.roomName,
            quitDate: _this.extraInfoData.checkoutDate,
            checkoutSum: _this.extraInfoData.checkoutSum,
            rentalStyle: _this.formData.leaseType,
            roomId: _this.formData.roomId,
            leaseId: _this.formData.leaseId,
            deductionDesc: _this.formData.deductionDesc,
            deductionFee: _this.formData.deductionFee,
            deductionPic: _this.formData.deductionPic,
            shortRentId: id,
          }
        });
      },
    }
  };
</script>
<style lang="scss">
  .lease-detail {
    .top {
      position: absolute;
      right: 0px;
      top: 60px
    }

    // .dotted-line{
    //   border: 1px #cccccc dotted;
    // }
    .title {
      border-left: 5px solid #cccccc;
      line-height: 18px;
      font-size: 14px;
      color: rgba(51, 51, 51, 0.8);
      padding-left: 9px;
      font-weight: 700;
      margin-top: 34px;
    }

    .cont-info {
      display: flex;
      padding-bottom: 33px;
      flex-wrap: wrap;

      .info-box {
        width: 25%;
        display: flex;
        font-size: 14px;
        color: #666666;
        margin-top: 20px;
        // margin-bottom: 10px;

        .lt {
          width: 40%;
          text-align: right;

        }

        .rt {
          width: 60%;
          text-align: left;
          // font-weight: 700;
        }
      }
    }

    .bottom-line {
      border-bottom: 1px solid #E4E7ED;
    }

    .el-form {
      margin: 0 auto;
      max-width: 1100px;

      .el-form-item {
        display: flex;
        justify-content: flex-start;
      }

      .el-form-item::before,
      .el-form-item::after {
        display: none !important;
      }


      .el-form-item__label {
        flex: 0.13;
      }

      .el-form-item__content {
        margin: 0;
        flex: 1;
      }

      .item-box-50 {
        display: inline-block;
        width: 50%;

        .el-form-item__label {
          flex: 0.3;
        }
      }

      .item-box-33 {
        display: inline-block;
        width: 33%;

        .el-form-item__label {
          flex: none;
          width: 100px;
        }
      }

      .item-content-67 {
        width: 67%;
      }

      .item-content-100 {
        width: 100%;
      }

      .item-box {
        width: 100%;
      }

      .item-hidden {
        visibility: hidden;
        width: 0;
        height: 0;
        overflow: hidden;
      }
    }
  }
</style>
<style lang="scss" scoped>
  .app-list {
    height: 590px !important;
  }
</style>