var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "table-box" },
    [
      _c(
        "el-row",
        { staticClass: "info1" },
        [
          _c(
            "el-table",
            { staticStyle: { width: "99.9%" }, attrs: { data: _vm.dataList } },
            _vm._l(_vm.columns1, function(item) {
              return _c("el-table-column", {
                key: item.key,
                attrs: { prop: item.key, label: item.label },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(scope) {
                      return [
                        item.key == "facePicUrl"
                          ? _c("div", { staticClass: "row-img" }, [
                              _c("img", {
                                staticClass: "table-row-img",
                                attrs: { src: scope.row[item.key] }
                              })
                            ])
                          : _c("span", [_vm._v(_vm._s(scope.row[item.key]))])
                      ]
                    }
                  }
                ])
              })
            }),
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }