var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "contract-detail" },
    _vm._l(_vm.dataList, function(item, index) {
      return _c("div", { key: index, staticClass: "contract-box" }, [
        _c("div", { staticClass: "top" }, [
          _c("div", { staticClass: "top-lt" }, [
            _c("span", [_vm._v("租约合同")]),
            _c("span", [_vm._v(_vm._s(item.title))])
          ]),
          _c("div", { staticClass: "top-rt" }, [
            _c("span", { staticClass: "state-txt" }, [
              _vm._v(_vm._s(item.stateStr))
            ])
          ])
        ]),
        _c("div", { staticClass: "cont" }, [
          _c("div", { staticClass: "list" }, [
            _c("div", { staticClass: "list-lt" }, [_vm._v("合同编号：")]),
            _c("div", { staticClass: "list-rt" }, [_vm._v(_vm._s(item.id))])
          ]),
          _c("div", { staticClass: "list" }, [
            _c("div", { staticClass: "list-lt" }, [_vm._v("签约主体人：")]),
            _c("div", { staticClass: "list-rt" }, [_vm._v(_vm._s(item.name))])
          ]),
          _c("div", { staticClass: "list" }, [
            _c("div", { staticClass: "list-lt" }, [_vm._v("电话：")]),
            _c("div", { staticClass: "list-rt" }, [
              _vm._v(_vm._s(item.phoneNumStr))
            ])
          ]),
          _c("div", { staticClass: "list" }, [
            _c("div", { staticClass: "list-lt" }, [_vm._v("租期：")]),
            _c("div", { staticClass: "list-rt" }, [
              _vm._v(_vm._s(item.tenancyTerm))
            ])
          ]),
          _c("div", { staticClass: "list" }, [
            _c("div", { staticClass: "list-lt" }, [_vm._v("合同起止日期：")]),
            _c("div", { staticClass: "list-rt" }, [
              _vm._v(_vm._s(item.contractDate))
            ])
          ]),
          _c("div", { staticClass: "list" }, [
            _c("div", { staticClass: "list-lt" }, [_vm._v("签约房间信息：")]),
            _c("div", { staticClass: "list-rt" }, [
              _vm._v(_vm._s(item.roomInfo))
            ])
          ]),
          _c("div", { staticClass: "list" }, [
            _c("div", { staticClass: "list-lt" }, [_vm._v("签约租金：")]),
            _c("div", { staticClass: "list-rt" }, [
              _vm._v(_vm._s(item.rentalFee) + "元/月")
            ])
          ]),
          _c("div", { staticClass: "list" }, [
            _c("div", { staticClass: "list-lt" }, [_vm._v("签约押金：")]),
            _c("div", { staticClass: "list-rt" }, [
              _vm._v(_vm._s(item.deposit) + "元")
            ])
          ]),
          _c("div", { staticClass: "list" }, [
            _c("div", { staticClass: "list-lt" }, [_vm._v("签约物业费：")]),
            _c("div", { staticClass: "list-rt" }, [
              _vm._v(_vm._s(item.propertyFee) + "元/月")
            ])
          ]),
          _c("div", { staticClass: "list" }, [
            _c("div", { staticClass: "list-lt" }, [_vm._v("签约时间：")]),
            _c("div", { staticClass: "list-rt" }, [
              _vm._v(_vm._s(item.createdDate))
            ])
          ]),
          _c("div", { staticClass: "list" }, [
            _c("div", { staticClass: "list-lt" }, [_vm._v("签约状态：")]),
            _c("div", { staticClass: "list-rt" }, [
              _vm._v(_vm._s(item.signingStatusStr))
            ])
          ]),
          _c("div", { staticClass: "list" }, [
            _c("div", { staticClass: "list-lt" }, [_vm._v("合同类型：")]),
            _c("div", { staticClass: "list-rt" }, [
              _vm._v(_vm._s(item.contractTypeStr) + " "),
              _c(
                "span",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: item.contractType == "2",
                      expression: "item.contractType=='2'"
                    }
                  ],
                  staticClass: "active",
                  on: {
                    click: function($event) {
                      _vm.lookBtn(item.downloadUrl)
                    }
                  }
                },
                [_vm._v("查看")]
              ),
              _c(
                "span",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: item.contractType == "2",
                      expression: "item.contractType=='2'"
                    }
                  ],
                  staticClass: "active",
                  on: {
                    click: function($event) {
                      _vm.downloadBtn(item.id)
                    }
                  }
                },
                [_vm._v("下载")]
              )
            ])
          ])
        ])
      ])
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }