<template>
  <div class="table-box">
         <el-row class="info1">
      <el-table :data="dataList" style="width: 99.9%">
        <el-table-column
          v-for="item in columns1"
          :key="item.key"
          :prop="item.key"
          :label="item.label"
        >
        <template slot-scope="scope">
          <div class="row-img" v-if="item.key=='facePicUrl'">
              <img :src="scope.row[item.key]" class="table-row-img" />
            </div>
            <span v-else>{{scope.row[item.key]}}</span>
        </template>
        </el-table-column>
      </el-table>
    </el-row>
  </div>
</template>
<script>
  export default {
    data() {
      let _this = this;
      return {
        title: "",
        shortOrderColumn: [{
            key: "orderNo",
            label: "订单编号"
          },
          {
            key: "startTime",
            label: "入住时间"
          },
          {
            key: "endTime",
            label: "截止时间"
          },
          {
            key: "payDate",
            label: "实缴时间"
          },
          {
            key: "orderName",
            label: "订单名称"
          },
          {
            key: "rentalFee",
            label: "租金",
            width: 80
          },
          {
            key: "depositFee",
            label: "押金",
            width: 80
          },
          {
            key: "orderAmount",
            label: "总金额",
            width: 100
          },
          {
            key: "payState",
            label: "订单状态",
            width: 80
          }
        ],
        longOrderColumn: [{
            key: "orderNo",
            label: "订单编号"
          },
          {
            key: "payRentalTerm",
            label: "应缴日期"
          },
          {
            key: "payDate",
            label: "实缴日期"
          },
          {
            key: "orderName",
            label: "订单名称"
          },
          {
            key: "rentalFee",
            label: "租金",
            width: 80
          },
          {
            key: "depositFee",
            label: "押金",
            width: 80
          },
          {
            key: "otherFee",
            label: "物业费",
            width: 80
          },
          {
            key: "electric",
            label: "电费",
            width: 80
          },
          {
            key: "coldWater",
            label: "冷水费",
            width: 80
          },
          {
            key: "hotWater",
            label: "热水费",
            width: 80
          },
          {
            key: "orderAmount",
            label: "总金额",
            width: 100
          },
          {
            key: "payState",
            label: "订单状态",
            width: 80
          }
        ],
        clockOrderColumn: [{
            key: "orderNo",
            label: "订单编号"
          },
          {
            key: "payRentalTerm",
            label: "应缴日期"
          },
          {
            key: "payDate",
            label: "实缴日期"
          },
          {
            key: "orderName",
            label: "订单名称"
          },
          {
            key: "rentalFee",
            label: "租金",
            width: 80
          },
          {
            key: "depositFee",
            label: "押金",
            width: 80
          },
          {
            key: "orderAmount",
            label: "总金额",
            width: 100
          },
          {
            key: "payState",
            label: "订单状态",
            width: 80
          }
        ],
         columns1: [],
         dataList:[],
         userId:''
      };
    },
    activated() {
      let leaseType = this.$route.query.leaseType;
      this.columns1.splice(0,this.columns1.length);
      if (this.columns1.length == 0) {
        if (leaseType == 2 || leaseType == 0) //长租
        {
          this.longOrderColumn.forEach(item => {
            this.columns1.push(item);
          })
        } else if (leaseType == '1') //短租
        {
          this.shortOrderColumn.forEach(item => {
            this.columns1.push(item);
          })
        } else if (leaseType == '3') //钟点房
        {
          this.clockOrderColumn.forEach(item => {
            this.columns1.push(item);
          })
        }
      }
      this.dataList=[];
     this.initGetUserId();
    },
    methods: {
      initGetUserId(){
       let _this = this;
        let dto = {
          roomId: this.$route.query.roomId,
        }
        if (this.$route.query.leaseType == '1') {
          dto.shortRentId = this.$route.params.id.substring(2, this.$route.params.id.length);
        } else if (this.$route.query.leaseType == '2'||this.$route.query.leaseType == '0' || this.$route.query.leaseType == '3') {
          dto.contractId = this.$route.params.id;
        }
        _this.post(
          '/landlord-service/leasingCenter/searchLeasingCenterRoom',
          dto).then(data => {
          this.initGetData(data.userId)
          })
      },
      initGetData(userId) {
        let leaseType = this.$route.query.leaseType;
        let query = this.$route.query
        let dto = {
          contractId: this.$route.params.id,
          orderType: (leaseType == 2 || leaseType == 0) ? 0 : leaseType,
          roomId: this.$route.query.roomId,
          userId: userId,
        };
        if(query.entrance == 2) {
          // dto.sign = 1
        }
        // 租约列表--详情--订单
        this.post("finance-service/leaseOrder/searchLeaseOrder", dto, {
          isUseResponse: true
        }).then(
          res => {
            res.data.data = res.data.data.reverse();
            res.data.data.forEach(item => {
              for (var key in item) {
                if (key == "payState") {
                  item[key] = [
                    "未支付",
                    "支付中",
                    "已支付",
                    "支付失败",
                    "订单取消",
                    "订单失效",
                    "已支付"
                  ][item.payState - 1];
                  
                }
              }
              if(item.orderType == '3') {
                item.payRentalTerm = item.createdDate
              } else {
                item.payRentalTerm = item.payRentalTerm ? item.payRentalTerm : "";
              }
              if (item.waterElectrics && item.waterElectrics[0]) {
                item.electric = item.waterElectrics[0].electric ?
                  "￥" + item.waterElectrics[0].electric :
                  "";
                item.coldWater = item.waterElectrics[0].coldWater ?
                  "￥" + item.waterElectrics[0].coldWater :
                  "";
                item.hotWater = item.waterElectrics[0].hotWater ?
                  "￥" + item.waterElectrics[0].hotWater :
                  "";
              }
            });
            this.dataList=res.data.data;
          }
        );
      }
    }
  };
</script>
<style lang="scss">
  .order-list__box {
    margin: 0 auto;
    max-width: 900px;
  }
</style>